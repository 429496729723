import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Grid,
    Typography,
} from '@material-ui/core';

const Toolbar = ({ className, state, corpcouponhistory, ...rest }) => {

    return (
        <div>
            <Box sx={{ backgroundColor: '#fff', paddingX: 3, paddingY: 4, borderRadius: 4, marginBottom: 10 }}>
                <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                    Coupon details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Coupon title
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Coupon_Title}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Discount %
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Discount_Percentage}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Coupon code
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Coupon_Code}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Coupon type
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Coupon_Type}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ backgroundColor: '#fff', paddingX: 3, paddingY: 4, borderRadius: 4, marginBottom: 10 }}>
                <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                    Creation details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Created by
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Created_By?.length > 25 ? `${corpcouponhistory[0]?.Created_By.substring(0, 25)}...` : corpcouponhistory[0]?.Created_By || ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Created on
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Created_Date}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Validity
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.From_Date} - {corpcouponhistory[0]?.To_Date}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ backgroundColor: '#fff', paddingX: 3, paddingY: 4, borderRadius: 4, marginBottom: 10 }}>
                <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                    Redemption details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Redemption limit
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Coupon_Max_Count}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            No of times redeemed
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Used_Count}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ backgroundColor: '#fff', paddingX: 3, paddingY: 4, borderRadius: 4, marginBottom: 5 }}>
                <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                    Applicability
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Recipient company
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Recipient_Company}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Recipient
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.User_Email?.length > 25 ? `${corpcouponhistory[0]?.User_Email.substring(0, 25)}...` : corpcouponhistory[0]?.User_Email || ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="textSecondary">
                            Applicable category
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {corpcouponhistory[0]?.Applicable_Category}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;