import {
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  Box,
  Avatar
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import {
  EditBannertypeAction,
  BanneruploadImagepath,
  getservicesbyid,
  getsubservicesbyid,
  getvarientsbyid
} from 'src/store/actions/servicebannersAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import { Autocomplete, CircularProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
  images: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const statusOption = [
  {
    value: '',
    label: '-Please Select-'
  },
  {
    value: 'ACTIVE',
    label: 'ACTIVE'
  },
  {
    value: 'IN-ACTIVE',
    label: 'IN-ACTIVE'
  }
];

const BannerTypes = [
  {
    value: 'Service',
    label: 'Service'
  },
  {
    value: 'SubService',
    label: 'Sub Service'
  },
  {
    value: 'Variant',
    label: 'Variant'
  },

];

const EditBannerService = ({ className, ...rest }) => {
  const location = useLocation();
  let { state } = location;

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedBannerType, setSelectedBannerType] = useState(state?.Offer_Type);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [services, setServices] = useState([]);
  const [subservices, setSubServices] = useState([]);
  const [Variants, setVarients] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [hasMore1, setHasMore1] = useState(true);
  const [hasMore2, setHasMore2] = useState(true);


  const [errorMessage, setErrorMessage] = useState({ Title: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.banner.bannerUpdate.isLoading);
  const { ServiceCategory } = useSelector(state => state.servicebanner.Servicecategories);
  const serviceSubcategories = useSelector(state => state.servicebanner?.Servicesubcategories || {});
  const { ServiceSubCategory = [], error = '', isLoading = false } = serviceSubcategories;
  const { ServiceVarient = [] } = useSelector(state => state.servicebanner?.Servicevarients || {});


  const createdBy = useSelector(state => state.auth.user.Email_Id);


  useEffect(() => {
    if (selectedBannerType === 'Service') {
        setServices([]); // Reset services state
    }
}, [selectedBannerType]);

  
  useEffect(() => {
    if (selectedBannerType === 'Service') {
      const fetchServices = async () => {
        try {
          setLoading(true);
          await dispatch(
            getservicesbyid(
              {},
              { PageNo: currentPage, PageSize: 15 },
              (status, data, pagination) => {
                if (status && Array.isArray(data)) {
                  setServices((prev) => [...prev, ...data]);
                  setHasMore(currentPage < pagination.TotalPages);
                }
              }
            )
          );
        } catch (error) {
          console.error('Error fetching services:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchServices();
    }
  }, [selectedBannerType, currentPage, dispatch]);
  

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      setCurrentPage((prevPage) => prevPage + 1);

    }
  };

  useEffect(() => {
    if (selectedBannerType === 'Sub Service' || selectedBannerType === "SubService") {
        setSubServices([]); 
    }
}, [selectedBannerType]);


  useEffect(() => {
    if (selectedBannerType === 'Sub Service'||selectedBannerType ==="SubService" ) {
    const fetchSubservices = async () => {
      try {

        setLoading1(true);
        await dispatch(
            getsubservicesbyid(
            {},
            { PageNo: currentPage1, PageSize: 21 },
            (status, data, pagination) => {
              if (status) {
                if (Array.isArray(data)) {
                  setSubServices((prev) => [...prev, ...data]);
                  setHasMore1(currentPage1 < pagination.TotalPages);
                } else {

                }
              }
            }
          )
        );
      } catch (error) {

      } finally {

        setLoading1(false);
      }
    };
    

    fetchSubservices();
}
  }, [selectedBannerType,currentPage1, dispatch]);

  const handleScroll1 = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore1 && !loading1) {
      setCurrentPage1((prevPage) => prevPage + 1);

    }
  };

  useEffect(() => {
    if (selectedBannerType === 'Variant') {
        setVarients([]); 
    }
}, [selectedBannerType]);

  useEffect(() => {
    if (selectedBannerType === 'Variant') {
      const fetchVarients = async () => {
        try {
          setLoading2(true);
          await dispatch(
            getvarientsbyid(
              {},
              { PageNo: currentPage2, PageSize: 12 },
              (status, data, pagination) => {
                if (status && Array.isArray(data)) {
                  setVarients((prev) => [...prev, ...data]);
                  setHasMore2(currentPage2 < pagination.TotalPages);
                }
              }
            )
          );
        } catch (error) {
          console.error('Error fetching services:', error);
        } finally {
          setLoading2(false);
        }
      };
  
      fetchVarients();
    }
  }, [selectedBannerType, currentPage2, dispatch]);

  const handleScroll2 = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore2 && !loading2) {
      setCurrentPage2((prevPage) => prevPage + 1);

    }
  };

  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(state.Description || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(state.Description);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };



  const [image, setImage] = useState({ preview: state.Image, raw: state.Image });
  const [imgUrl, setImgUrl] = useState(state.Image);
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImgae = data => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = e => {
    setUploadPic(true);
    e.preventDefault();
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    });
    const data = new FormData();
    data.append('file', e.target.files[0]);
    dispatch(
      BanneruploadImagepath(new Blob([e.target.files[0]]), callBackUploadImgae)
    );
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  const values = {
    Banner_Id: state.Banner_Id ? state.Banner_Id : "",
    Title: state.Title ? state.Title : "",
    Offer_Type: state.Offer_Type ? state.Offer_Type : "",
    Offer_Id: state?.Offer_Id ? state?.Offer_Id : "",
    Description: state.Description ? state.Description : "",
    Image: imgUrl,
    Status: state.Status ? state.Status : "",
    Banner_From_Date: state.Banner_From_Date ? state.Banner_From_Date : "",
    Banner_To_Date: state.Banner_To_Date ? state.Banner_To_Date : "",

    Modified_By: createdBy,
    Banner_Priority_Sequence: state.Banner_Priority_Sequence ? state.Banner_Priority_Sequence : "",
  }

  const Form_Validation = Yup.object().shape({
    Title: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Please remove special characters & white spaces at the beginning for Banner Title.").min(3, " Title requires atleast 3 characters.").notOneOf([errorMessage.Title.key, null], errorMessage.Title.message).required("Title is required."),
    Status: Yup.string().required('Status is required.'),
    Banner_From_Date: Yup.date().min(new Date(state.Banner_From_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? new Date(new Date(state.Banner_From_Date) - 1 * 24 * 60 * 60 * 1000) : new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), new Date(state.Banner_From_Date) < new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) ? "Required From Date greater than or equal to " + ((new Date(state.Banner_From_Date).getMonth() > 8) ? (new Date(state.Banner_From_Date).getMonth() + 1) : ('0' + (new Date(state.Banner_From_Date).getMonth() + 1))) + '/' + ((new Date(state.Banner_From_Date).getDate() > 9) ? new Date(state.Banner_From_Date).getDate() : ('0' + new Date(state.Banner_From_Date).getDate())) + '/' + new Date(state.Banner_From_Date).getFullYear() + "." : "Required From Date greater than or equal to today.").required("From Date is required."),
    Banner_To_Date: Yup.date().min(Yup.ref('Banner_From_Date'), "To Date can't be before From Date.").required("Banner To Date is required."),
    Offer_Id: Yup.string().when("Offer_Type", (Offer_Type, schema) => {
      return Offer_Type ? schema.required('Banner Offer By ' + Offer_Type + ' is required.') : schema.required(' Offer By is required.')
    }),
    Offer_Type: Yup.string().required(' Offer Type is required.'),
    Banner_Priority_Sequence: Yup.number().min(1, "Banner Priority Sequence must be at least 1.").required('Banner Priority Sequence is required.'),
  })
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(false);
    let submitTest = true;
    const errors = {};
    values.Image = imgUrl;
    values.Description = rhDiscription;
    if (!values.Image) {
      errors.Image = "Image is required.";
      submitTest = false;
    } if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
      submitTest = false;
      errors.Description = "Description is required.";
    }
    else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
      submitTest = false;
      errors.Description = "Please provide at least " + rhDiscriptionMin + " characters";
    }
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(EditBannertypeAction(formValues, navigate, setErrorMessage, setErrors));
    }
  }
  return (
    <Formik initialValues={{ ...values }} validationSchema={Form_Validation} onSubmit={onSubmit}>
      {({
        errors,
        handleBlur,
        handleChange,
        values,
        touched,
        setFieldValue
      }) => (
        <Form>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Title && errors.Title)}
                        label=" Title"
                        name="Title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Title}
                        variant="outlined"
                        helperText={<ErrorMessage name="Title" />}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Offer_Type && errors.Offer_Type)}
                        fullWidth
                        helperText={touched.Offer_Type && errors.Offer_Type}
                        onBlur={(e) => {
                          handleBlur(e); 
                          setCurrentPage(1);
                         
                          setCurrentPage1(1);
                          
                          setCurrentPage2(1);
                          
                        }}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("Offer_Id", "");
                            if (e.target.value === "Service") {
                                                        setSelectedBannerType("Service");
                                                      } else {
                                                        setSelectedBannerType(e.target.value); // Update the state with other selected values
                                                      }
                                                  
                                              
                        }}
                        label="Banner Offer Type"
                        name="Offer_Type"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={values.Offer_Type}
                        variant="outlined"
                      >
                        <option key="" value="">--Please Select--</option>
                        {BannerTypes.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        fullWidth
                        size="small"
                        options={
                          values.Offer_Type === "Service"
                            ? services?.sort((a, b) =>
                              a.Service_Name?.localeCompare(b.Service_Name)
                            )
                            : values.Offer_Type === "SubService"
                              ? subservices?.sort((a, b) =>
                                a.Ss_Name?.localeCompare(b.Ss_Name)
                              )
                              : values.Offer_Type === "Variant"
                                ? Variants?.sort((a, b) =>
                                  a.Sv_Name?.localeCompare(b.Sv_Name)
                                )
                                : []
                        }
                        getOptionLabel={(option) =>
                          values.Offer_Type === "Service"
                            ? option.Service_Name || ""
                            : values.Offer_Type === "SubService"
                              ? option.Ss_Name || ""
                              : values.Offer_Type === "Variant"
                                ? option.Sv_Name || ""
                                : ""
                        }
                        value={
                          values.Offer_Type === "Service"
                            ? services?.find((item) => item.Service_Id === values.Offer_Id) || null
                            : values.Offer_Type === "SubService"
                              ? subservices?.find((item) => item.Ss_Id === values.Offer_Id) || null
                              : values.Offer_Type === "Variant"
                                ? Variants?.find((item) => item.Sv_Id === values.Offer_Id) || null
                                : null
                        }
                        onChange={(event, newValue) => {
                          handleChange({
                            target: {
                              name: "Offer_Id",
                              value:
                                values.Offer_Type === "Service"
                                  ? newValue?.Service_Id || ""
                                  : values.Offer_Type === "SubService"
                                    ? newValue?.Ss_Id || ""
                                    : values.Offer_Type === "Variant"
                                      ? newValue?.Sv_Id || ""
                                      : "",
                            },
                          });
                        }}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(touched.Offer_Id && errors.Offer_Id)}
                            helperText={touched.Offer_Id && errors.Offer_Id}
                            onBlur={handleBlur}
                            label="Offer Name"
                            variant="outlined"
                            placeholder="--Please Select--"
                          />
                        )}
                        ListboxProps={{
                          onScroll: (event) => {
                            if (values.Offer_Type === "Service") {
                            
                              handleScroll(event);
                            } else if (values.Offer_Type === "Sub Service"|| values.Offer_Type === "SubService") {
                              
                              handleScroll1(event);
                            } else if (values.Offer_Type === "Variant") {
                              handleScroll2(event);
                            }
                          },
                        }}
                        noOptionsText="No options available"
                        isOptionEqualToValue={(option, value) =>
                          values.Offer_Type === "Service"
                            ? option.Service_Id === value?.Service_Id
                            : values.Offer_Type === "SubService"
                              ? option.Ss_Id === value?.Ss_Id
                              : values.Offer_Type === "Variant"
                                ? option.Sv_Id === value?.Sv_Id
                                : false
                        }
                      />

                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box className={classes.description}>
                        Description
                        <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                      </Box>
                      <span style={{ color: "red" }}>
                        {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                      </span>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        error={Boolean(touched.Banner_From_Date && errors.Banner_From_Date)}
                        type="datetime-local"
                        label="Banner From Date"
                        name="Banner_From_Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Banner_From_Date}
                        variant="outlined"
                        helperText={<ErrorMessage name="Banner_From_Date" />}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field as={TextField}
                        fullWidth
                        error={Boolean(touched.Banner_To_Date && errors.Banner_To_Date)}
                        type="datetime-local"
                        label="Banner To Date"
                        name="Banner_To_Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Banner_To_Date}
                        variant="outlined"
                        helperText={<ErrorMessage name="Banner_To_Date" />}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.Banner_Priority_Sequence && errors.Banner_Priority_Sequence)}
                        fullWidth
                        helperText={touched.Banner_Priority_Sequence && errors.Banner_Priority_Sequence}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Banner Priority Sequence"
                        name="Banner_Priority_Sequence"
                        value={values.Banner_Priority_Sequence}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.Status && errors.Status)}
                        label="Status"
                        name="Status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={values.Status}
                        variant="outlined"
                        helperText={<ErrorMessage name="Status" />}
                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        {image.preview ? (
                          <Avatar
                            className={classes.images}
                            alt="Brand Upload"
                            src={image.preview}
                            variant="square"
                          />
                        ) : (
                          <>
                            <Avatar
                              className={classes.images}
                              alt="Brand Upload"
                              src="/static/images/image-placeholder.png"
                              variant="square"
                            />
                          </>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {image.preview ? <Button
                          style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                          onClick={handleRemoveImage}
                        >
                          Remove Picture
                        </Button> : <input
                          style={{ display: 'block', width: '80%' }}
                          id="upload-photo"
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                        />}
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                  <span style={{ color: "red" }}>
                    {!imgUrl && touched.Image && errors.Image}
                  </span>
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" pl={2} >
              <span style={{ color: "red" }}>{errorMessage.global.message}</span>
            </Box>
            <Box display="flex" p={2}>
              {isSubmited ?
                <CircularProgress />
                :
                <Button disabled={isSubmited || uploadPic}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained" >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              }
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

EditBannerService.propTypes = {
  className: PropTypes.string
};

export default EditBannerService;