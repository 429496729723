import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box, TextField, InputAdornment, SvgIcon, makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Autocomplete } from '@mui/material';
import { Partner_Get } from 'src/store/actions/node-actions/nodepagination';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Toolbar = ({ className, filters, setfilters, setPartnerId, ...rest }) => {
  const classes = useStyles();
  const [partners, setPartners] = useState([])
  const [dropdownCatValue, setDropdownCatValue] = useState(null);

  useEffect(() => {
    Partner_Get_Call();
  }, [])

  const handleChange = event => {
    setfilters({
      Search: filters?.Search,
      Search_by_filter: event.target.value
    })
  };

  const Partner_Get_Call = async () => {
    try {
      const formData = {
        "search": '',
        "search_by_filter": 'ALL',
        Records_Filter: "FILTER",
        Status: "Active"
      }
      const response = await Partner_Get(formData);
      if (response.status) {
        setPartners(response.data);
      } else {
        setPartners([]);
      }
    } catch (error) {
      setPartners([]);
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    setDropdownCatValue(newValue);
    if (newValue) {
      setPartnerId(newValue.Partner_Details_Id);
    } else {
      setPartnerId('')
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value={filters?.Search_by_filter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Transaction_Id">Transaction ID</MenuItem>
              <MenuItem value="User_Details_Id">User Detail Id</MenuItem>
              <MenuItem value="Coupon_Utilisation_Id">Coupon ID</MenuItem>
              <MenuItem value="Payment_Transaction_Status">Transaction Status</MenuItem>
            </Select>
          </FormControl>
          <Box width={300}>
            <TextField
              onChange={(event) => {
                setfilters({
                  Search: event.target.value,
                  Search_by_filter: filters?.Search_by_filter
                })
              }}
              className={classes.formControl}
              value={filters?.Search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search"
              variant="outlined"
            />
          </Box>
        </Box>
        <Box style={{ width: '325px', marginTop: '-20px' }}>
          <Autocomplete
            options={partners}
            getOptionLabel={(option) => option.Name}
            value={dropdownCatValue}
            onChange={handleAutocompleteChange}
            renderInput={(params) => <TextField {...params} label="Select Partner" variant="outlined" />}
          />
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;