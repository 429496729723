import { toast } from 'react-toastify';
import { corpimageuploadApi, nodeCorpMongoApi } from "./commonAxios";
import { paginationAllCorpCouponGet } from "./node-actions/nodepagination";

export const CORP_COUPON_SUBSCRIPTION_REQUEST = "CORP_COUPON_SUBSCRIPTION_REQUEST";
export const CORP_COUPON_SUBSCRIPTION_SUCCESS = "CORP_COUPON_SUBSCRIPTION_SUCCESS";
export const CORP_COUPON_SUBSCRIPTION_FAIL = "CORP_COUPON_SUBSCRIPTION_FAIL";

export const CORP_COUPON_IMAGES_REQUEST = "CORP_COUPON_IMAGES_REQUEST";
export const CORP_COUPON_IMAGES_SUCCESS = "CORP_COUPON_IMAGES_SUCCESS";
export const CORP_COUPON_IMAGES_FAIL = "CORP_COUPON_IMAGES_FAIL";

export const CORP_COUPON_CREATE_REQUEST = "CORP_COUPON_CREATE_REQUEST";
export const CORP_COUPON_CREATE_SUCCESS = "CORP_COUPON_CREATE_SUCCESS";
export const CORP_COUPON_CREATE_FAIL = "CORP_COUPON_CREATE_FAIL";

export const CORP_COUPON_UPDATE_REQUEST = "CORP_COUPON_UPDATE_REQUEST";
export const CORP_COUPON_UPDATE_SUCCESS = "CORP_COUPON_UPDATE_SUCCESS";
export const CORP_COUPON_UPDATE_FAIL = "CORP_COUPON_UPDATE_FAIL";

export const CORP_COMPANY_NAMES_REQUEST = "CORP_COMPANY_NAMES_REQUEST";
export const CORP_COMPANY_NAMES_SUCCESS = "CORP_COMPANY_NAMES_SUCCESS";
export const CORP_COMPANY_NAMES_FAIL = "CORP_COMPANY_NAMES_FAIL";

export const CORP_COMPANY_EMPLOYEES_REQUEST = "CORP_COMPANY_EMPLOYEES_REQUEST";
export const CORP_COMPANY_EMPLOYEES_SUCCESS = "CORP_COMPANY_EMPLOYEES_SUCCESS";
export const CORP_COMPANY_EMPLOYEES_FAIL = "CORP_COMPANY_EMPLOYEES_FAIL";


export const createCorpCouponAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: CORP_COUPON_CREATE_REQUEST
  });
  nodeCorpMongoApi.post(`/Corp_Coupon/create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse?.code === 201 && successResponse?.message === "coupon created successfully") {
        dispatch({
          type: CORP_COUPON_CREATE_SUCCESS
        });
        toast('Corporate coupon created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/corporate-coupon-management');
      } else {
        dispatch({
          type: CORP_COUPON_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CORP_COUPON_CREATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

export const updatedCorpCouponAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: CORP_COUPON_UPDATE_REQUEST
  });
  nodeCorpMongoApi.post(`/Corp_Coupon/update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse?.code === 200 && successResponse?.message === "corp coupon updated successfully") {
        dispatch({
          type: CORP_COUPON_UPDATE_SUCCESS
        });
        toast('Corporate coupon updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/corporate-coupon-management');
      } else {
        dispatch({
          type: CORP_COUPON_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CORP_COUPON_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working"
      });
    })
};

export const getcorpCouponImages = () => async dispatch => {
  try {
    dispatch({
      type: CORP_COUPON_IMAGES_REQUEST
    });

    let { data } = await nodeCorpMongoApi.post(`/corpCouponImage/get`);
    if (data) {
      if (data.status < 200 || data.status >= 300) {
        dispatch({
          type: CORP_COUPON_IMAGES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_COUPON_IMAGES_SUCCESS,
          payload: data
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: CORP_COUPON_IMAGES_FAIL,
      payload: err
    });
  }
};

export const getAllCorpSubscriptionAction = () => async dispatch => {
  try {
    dispatch({
      type: CORP_COUPON_SUBSCRIPTION_REQUEST
    });

    let { data } = await nodeCorpMongoApi.get(`/corpSASubscription/get`);
    if (data) {
      if (data.status < 200 || data.status >= 300) {
        dispatch({
          type: CORP_COUPON_SUBSCRIPTION_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_COUPON_SUBSCRIPTION_SUCCESS,
          payload: data
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: CORP_COUPON_SUBSCRIPTION_FAIL,
      payload: err
    });
  }
};

export const uploadCorpCouponImage = (
  formData,
  callBackUploadImgae
) => async () => {
  try {
    let { data } = await corpimageuploadApi.post(`/`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};

export const deletedCorpCouponAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCorpMongoApi.post(`Corp_Coupon/delete`, formData);
    if (data) {
      toast('Corporate Coupon deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationAllCorpCouponGet(filters, pagination, callBackPagination));
    }
  } catch (err) {
  }
};

export const getAllCorpCompanyNamesAction = (formData) => async dispatch => {
  try {
    dispatch({
      type: CORP_COMPANY_NAMES_REQUEST
    });

    let { data } = await nodeCorpMongoApi.post(`/Corp_Coupon/companyNamesGet`, formData);
    if (data) {
      if (data.status < 200 || data.status >= 300) {
        dispatch({
          type: CORP_COMPANY_NAMES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_COMPANY_NAMES_SUCCESS,
          payload: data
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: CORP_COMPANY_NAMES_FAIL,
      payload: err
    });
  }
};

export const getAllCorpEmployeesAction = (formData) => async dispatch => {
  try {
    dispatch({
      type: CORP_COMPANY_EMPLOYEES_REQUEST
    });

    let { data } = await nodeCorpMongoApi.post(`/Corp_Coupon/companyEmployeesGet`, formData);
    if (data) {
      if (data.status < 200 || data.status >= 300) {
        dispatch({
          type: CORP_COMPANY_EMPLOYEES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_COMPANY_EMPLOYEES_SUCCESS,
          payload: data
        });
      }
    }
  } catch (err) {
    toast("Something went wrong please load page again", 3000);
    dispatch({
      type: CORP_COMPANY_EMPLOYEES_FAIL,
      payload: err
    });
  }
};