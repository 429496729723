import config from './config';
import { toast } from 'react-toastify';
import { paginationPendingProducts, paginationProductsCorp } from './node-actions/nodepagination';
import { nodeGetApi, boomiAdminApi, nodeCorpMongoApi } from './commonAxios';

export const CORP_PRODUCTS_REQUEST = "CORP_PRODUCTS_REQUEST";
export const CORP_PRODUCTS_SUCCESS = "CORP_PRODUCTS_SUCCESS";
export const CORP_PRODUCTS_FAIL = "CORP_PRODUCTS_FAIL";

export const CORP_PRODUCTS_GET_REQUEST = "CORP_PRODUCTS_GET_REQUEST";
export const CORP_PRODUCTS_GET_SUCCESS = "CORP_PRODUCTS_GET_SUCCESS";
export const CORP_PRODUCTS_GET_FAIL = "CORP_PRODUCTS_GET_FAIL";

export const CORP_PRODUCTS_CREATE_REQUEST = "CORP_PRODUCTS_CREATE_REQUEST";
export const CORP_PRODUCTS_CREATE_SUCCESS = "CORP_PRODUCTS_CREATE_SUCCESS";
export const CORP_PRODUCTS_CREATE_FAIL = "CORP_PRODUCTS_CREATE_FAIL";

export const CORP_PRODUCTS_UPDATE_REQUEST = "CORP_PRODUCTS_UPDATE_REQUEST";
export const CORP_PRODUCTS_UPDATE_SUCCESS = "CORP_PRODUCTS_UPDATE_SUCCESS";
export const CORP_PRODUCTS_UPDATE_FAIL = "CORP_PRODUCTS_UPDATE_FAIL";

export const CORP_PRODUCTS_DELETE_REQUEST = "CORP_PRODUCTS_DELETE_REQUEST";
export const CORP_PRODUCTS_DELETE_SUCCESS = "CORP_PRODUCTS_DELETE_SUCCESS";
export const CORP_PRODUCTS_DELETE_FAIL = "CORP_PRODUCTS_DELETE_FAIL";

export const CORP_PRODUCTS_BRANDS_REQUEST = "CORP_PRODUCTS_BRANDS_REQUEST";
export const CORP_PRODUCTS_BRANDS_SUCCESS = "CORP_PRODUCTS_BRANDS_SUCCESS";
export const CORP_PRODUCTS_BRANDS_FAIL = "CORP_PRODUCTS_BRANDS_FAIL";

export const CORP_PRODUCTS_CATEGORIES_REQUEST = "CORP_PRODUCTS_CATEGORIES_REQUEST";
export const CORP_PRODUCTS_CATEGORIES_SUCCESS = "CORP_PRODUCTS_CATEGORIES_SUCCESS";
export const CORP_PRODUCTS_CATEGORIES_FAIL = "CORP_PRODUCTS_CATEGORIES_FAIL";

export const CORP_PRODUCTS_SUBCATEGORIES_REQUEST = "CORP_PRODUCTS_SUBCATEGORIES_REQUEST";
export const CORP_PRODUCTS_SUBCATEGORIES_SUCCESS = "CORP_PRODUCTS_SUBCATEGORIES_SUCCESS";
export const CORP_PRODUCTS_SUBCATEGORIES_FAIL = "CORP_PRODUCTS_SUBCATEGORIES_FAIL";

export const CORP_PRODUCTS_COLOURS_REQUEST = "CORP_PRODUCTS_COLOURS_REQUEST";
export const CORP_PRODUCTS_COLOURS_SUCCESS = "CORP_PRODUCTS_COLOURS_SUCCESS";
export const CORP_PRODUCTS_COLOURS_FAIL = "CORP_PRODUCTS_COLOURS_FAIL";

export const CORP_PRODUCTS_ROLES_REQUEST = "CORP_PRODUCTS_ROLES_REQUEST";
export const CORP_PRODUCTS_ROLES_SUCCESS = "CORP_PRODUCTS_ROLES_SUCCESS";
export const CORP_PRODUCTS_ROLES_FAIL = "CORP_PRODUCTS_ROLES_FAIL";

export const CORP_PRODUCTS_MASTER_REQUEST = "CORP_PRODUCTS_MASTER_REQUEST";
export const CORP_PRODUCTS_MASTER_SUCCESS = "CORP_PRODUCTS_MASTER_SUCCESS";
export const CORP_PRODUCTS_MASTER_FAIL = "CORP_PRODUCTS_MASTER_FAIL";

export const CORP_PRODUCTS_SIZE_REQUEST = "CORP_PRODUCTS_SIZE_REQUEST";
export const CORP_PRODUCTS_SIZE_SUCCESS = "CORP_PRODUCTS_SIZE_SUCCESS";
export const CORP_PRODUCTS_SIZE_FAIL = "CORP_PRODUCTS_SIZE_FAIL";

export const CORP_PRODUCTS_APPROVE_REQUEST = "CORP_PRODUCTS_APPROVE_REQUEST";
export const CORP_PRODUCTS_APPROVE_SUCCESS = "CORP_PRODUCTS_APPROVE_SUCCESS";
export const CORP_PRODUCTS_APPROVE_FAIL = "CORP_PRODUCTS_APPROVE_FAIL";

export const CORP_PRODUCTS_MASTER_FILTER_REQUEST = "CORP_PRODUCTS_MASTER_FILTER_REQUEST";
export const CORP_PRODUCTS_MASTER_FILTER_SUCCESS = "CORP_PRODUCTS_MASTER_FILTER_SUCCESS";
export const CORP_PRODUCTS_MASTER_FILTER_FAIL = "CORP_PRODUCTS_MASTER_FILTER_FAIL";

export const CORP_PRODUCTS_FILTER_REQUEST = "CORP_PRODUCTS_FILTER_REQUEST";
export const CORP_PRODUCTS_FILTER_SUCCESS = "CORP_PRODUCTS_FILTER_SUCCESS";
export const CORP_PRODUCTS_FILTER_FAIL = "CORP_PRODUCTS_FILTER_FAIL";

export const CORP_PRODUCTS_REQUEST_UPDATE_REQUEST = "CORP_PRODUCTS_REQUEST_UPDATE_REQUEST";
export const CORP_PRODUCTS_REQUEST_UPDATE_SUCCESS = "CORP_PRODUCTS_REQUEST_UPDATE_SUCCESS";
export const CORP_PRODUCTS_REQUEST_UPDATE_FAIL = "CORP_PRODUCTS_REQUEST_UPDATE_FAIL";





export const getsingleProductDetails = (formData, responseFunction) => async (dispatch) => {
  let { data } = await nodeCorpMongoApi.post(`/corp_SA_Product/updatePageGet`, formData);
  if (data) {
    try {
      responseFunction(true, data);
    } catch (error) {
      responseFunction(true, data);
    }
  }
}

export const getMRP = (categoryID, price, responseFunction) => async (dispatch) => {
  try {
    const response = await nodeCorpMongoApi.get(
      `/corp_SA_Product/mrpGet?Category_Id=${categoryID}&Product_Selling_Price=${price}`
    );
    if (response.status === 201) {
      responseFunction(true, response.data);
    } else {
      toast(response.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      responseFunction(false, response.data);
    }
  } catch (error) {
    toast(error.response?.data?.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    responseFunction(false, {});
  }
};

export const getAllFilterCategories = (categoryName, responseFunction) => async (dispatch) => {
  let { data } = await nodeCorpMongoApi.get(`/corpSAProductCategory/get?page=1&limit=100&sortBy=createdAt:desc&Status=Active&Search_By_Filter=All&Search=${categoryName}`
  );
  if (data) {
    if (data.Pagination.totalResults === "0") {
      responseFunction(true, []);
    } else {
      try {
        responseFunction(true, data.results);
      } catch (error) {
        responseFunction(true, data.results);
      }
    }
  }
}

export const getAllProductlabels = (responseFunction) => async (dispatch) => {
  let { data } = await nodeCorpMongoApi.get(`/corpSAProductLabel/get`
  );
  if (data) {
    if (data.Pagination.totalResults === "0") {
      responseFunction(true, []);
    } else {
      try {
        responseFunction(true, data.results);
      } catch (error) {
        responseFunction(true, data.results);
      }
    }
  }
}
/*=============================================================
                  Add Product Action
===============================================================*/
export const createProductAction = (formData, navigate, setErrorMessage, setErrors) => async (dispatch) => {
  dispatch({
    type: CORP_PRODUCTS_CREATE_REQUEST
  });
  setErrorMessage({ global: { key: '', message: '' } });
  try {
    const response = await nodeCorpMongoApi.post(`/corp_SA_Product/create`, formData);
    let successResponse = response.data;
    if (successResponse.code === 201) {
      dispatch({
        type: CORP_PRODUCTS_CREATE_SUCCESS
      });
      toast('Corp Product added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      navigate('/app/corporate-product-management');
    } else {
      const errors = {};
      if (successResponse.message) {
        errors.global = successResponse.message;
      }
      setErrors(errors);
      setErrorMessage({ global: { key: '', message: successResponse.message || '' } });
      dispatch({
        type: CORP_PRODUCTS_CREATE_FAIL,
        payload: successResponse.message
      });
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Currently server is not working. Please try again later.";
    setErrorMessage({ global: { key: '', message: errorMessage } });
    dispatch({
      type: CORP_PRODUCTS_CREATE_FAIL,
      payload: errorMessage
    });
  }
};

/*=============================================================
                  Update Product Action
===============================================================*/
export const updateProductAction = (formData, navigate, setErrorMessage, setErrors) => async (dispatch) => {
  dispatch({
    type: CORP_PRODUCTS_UPDATE_REQUEST
  });
  setErrorMessage({ global: { key: '', message: '' } });
  try {
    const response = await nodeCorpMongoApi.post(`/corp_SA_Product/update`, formData);
    let successResponse = response.data;
    if (successResponse.code === 200) {
      dispatch({
        type: CORP_PRODUCTS_UPDATE_SUCCESS
      });
      toast('Product updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      navigate('/app/corporate-product-management');
    } else {
      const errors = {};
      if (successResponse.message) {
        errors.global = successResponse.message;
      }
      setErrors(errors);
      setErrorMessage({ global: { key: '', message: successResponse.message || '' } });
      dispatch({
        type: CORP_PRODUCTS_UPDATE_FAIL,
        payload: successResponse.message
      });
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Currently server is not working. Please try again later.";
    setErrorMessage({ global: { key: '', message: errorMessage } });
    dispatch({
      type: CORP_PRODUCTS_UPDATE_FAIL,
      payload: errorMessage
    });
  }
};

/*=============================================================
                  Product Image Delete Action
===============================================================*/
export const imageDelete = (input, callBackfunction, key) => async dispatch => {
  let payload = {
    "Product_Image_Id": input,
    "Record_Filter": "H"
  };
  boomiAdminApi.post(`/Products/Product_Image_Delete`, payload
  )
    .then(response => {
      let data = response.data;
      callBackfunction(input, data.Response_Status === "Success" ? true : false, key);
    })
}

/*=============================================================
                 getProductImages Action
===============================================================*/
export const getProductImages = (productId, callBackfunction, key) => async dispatch => {
  let payload = {
    "Records_Filter": "FILTER",
    "Product_Image_Id": "",
    "Status": "",
    "Product_Id": productId
  };
  nodeGetApi.post(`/Products/Product_Image_Get?PageNo=1&PageSize=10&SortBy=Product_Id&SortOrder=ASC`, payload)
    .then(response => {
      let data = response.data;
      if (data.Success_Response.Is_Data_Exist === "0" && data.Success_Response.Response_Status === "Success") {
        callBackfunction([])
      }
      else if (data.Success_Response.Is_Data_Exist === "1" && data.Success_Response.Response_Status === "Success") {
        callBackfunction(data.results[0].Product_Image, key);
      } else {
        callBackfunction([]);
      }
    })
}

/*=============================================================
                 singleImageUploadCreate Action
===============================================================*/
export const singleImageUploadCreate = (image, callBackfunction, key) => async dispatch => {
  boomiAdminApi.post(`/SA_Upload/Upload?functionality=Products&fileextension=png&filetype=Images&filename=Products`, image
  )
    .then(response => {
      let data = response.data;
      if (data.File_URL) {
        callBackfunction(true, data.File_URL, key);
      }
      else {
        callBackfunction(false, data.File_URL, key);
      }
    }).catch(error => {
      callBackfunction(false, "", key);
    })
}

export const singleImageUploadUpdate = (image, productId, modifiedBy, key, callBackfunction) => async dispatch => {
  boomiAdminApi.post(`/SA_Upload/Upload?functionality=Products&fileextension=png&filetype=Images&filename=Products`, image)
    .then(response => {
      let successResponse = response.data;
      let data = response.data;
      if (successResponse.Response_Status === "Success") {
        let imageCreate = {
          "Created_By": modifiedBy,
          "Product_Id": productId
        }
        let Images = [];
        Images.push({
          "Image_Url": data.File_URL,
          "Is_Default": "0",
          "Sort": key
        });
        imageCreate.Images = Images;
        boomiAdminApi.post(`/Multiproductimage/Images_Create`, imageCreate
        )
          .then(response => {
            callBackfunction(true);
          }).catch(error => {
            callBackfunction(false);
          })
      }
    }).catch(error => {
      callBackfunction(false);
    })
}

/*=============================================================
                 imageDefaultUpdate Action
===============================================================*/
export const imageDefaultUpdate = (productId, modifiedBy, Product_Image_Id, callBackfunction) => async dispatch => {
  boomiAdminApi.put(`/Products/Product_Image_Update`,
    {
      "Is_Default": 1,
      "Modified_By": modifiedBy,
      "Product_Id": productId,
      "Product_Image_Id": Product_Image_Id,
    }
  )
    .then(response => {
      let data = response.data;
      if (data.Response_Status === "Success") {
        callBackfunction(true);
      }
      else {
        callBackfunction(false);
      }
    }).catch(error => {
      callBackfunction(false);
    })
}

/*=============================================================
                  Get All Product Action
===============================================================*/
export const getAllProductAction = formData => async dispatch => {
  try {
    dispatch({
      type: CORP_PRODUCTS_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Get_Admin?PageNo=1&PageSize=20&SortBy=PRODUCT_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CORP_PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_PRODUCTS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORP_PRODUCTS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  delete product Action
===============================================================*/
export const deleteProductAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeCorpMongoApi.post(`/corp_SA_Product/delete`, formData
    );
    if (data) {
      toast('Product deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      dispatch(paginationProductsCorp(filters, pagination, callBackPagination));
    }
  } catch (err) { }
};

/*=============================================================
                  Get Single Product Action
===============================================================*/
export const getSingleProduct = (formData, callBackProductData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Products/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Product_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      callBackProductData(data)
    }
  } catch (err) {
  }
};

/*=============================================================
                  Get All Brands Action
===============================================================*/
export const getAllBrands = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Brand_Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: CORP_PRODUCTS_BRANDS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Brand/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=BRAND_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CORP_PRODUCTS_BRANDS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_PRODUCTS_BRANDS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORP_PRODUCTS_BRANDS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                   Get All Categories Action
===============================================================*/
export const getAllCategories = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: CORP_PRODUCTS_CATEGORIES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Categories/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=CATEGORY_ID&SortOrder=ASC`,
      formData,

    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: CORP_PRODUCTS_CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_PRODUCTS_CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORP_PRODUCTS_CATEGORIES_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Sub Categories Action
===============================================================*/
export const getAllSubCategories = () => async dispatch => {
  try {
    let formData =
    {
      Records_Filter: "FILTER",
      Status: "Active",
      search_by_filter: "",
      search: "",
    };
    dispatch({
      type: CORP_PRODUCTS_SUBCATEGORIES_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Categories/Sub_Category_Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Sub_Category_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: CORP_PRODUCTS_SUBCATEGORIES_SUCCESS,
          payload: []
        });
      } else {

        dispatch({
          type: CORP_PRODUCTS_SUBCATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORP_PRODUCTS_SUBCATEGORIES_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Colours Action
===============================================================*/
export const getAllColours = (Product_Master_id) => async dispatch => {
  try {
    let formData = {
      Product_Master_Id: Product_Master_id,
    };
    dispatch({
      type: CORP_PRODUCTS_COLOURS_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Product_Remain_Color_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=PRODUCT_MASTER_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CORP_PRODUCTS_COLOURS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_PRODUCTS_COLOURS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORP_PRODUCTS_COLOURS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Masters Action
===============================================================*/
export const getAllMasters = (Product_Master_Id) => async dispatch => {
  try {
    let formData = {
      Records_Filter: "FILTER",
      Status: "",
      search_by_filter: "",
      search: "",
      Product_Master_Id: Product_Master_Id
    };
    dispatch({
      type: CORP_PRODUCTS_MASTER_REQUEST
    });
    let { data } = await nodeGetApi.post(`/Products/Product_Master_Get_Admin?PageNo=1&PageSize=20&SortBy=PRODUCT_MASTER_ID&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CORP_PRODUCTS_MASTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_PRODUCTS_MASTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORP_PRODUCTS_MASTER_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Sizes Action
===============================================================*/
export const getAllSizes = (Product_Master_id) => async dispatch => {
  try {
    let formData = {
      Product_Master_Id: Product_Master_id,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: CORP_PRODUCTS_SIZE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Product_Remain_Size_Get?PageNo=1&PageSize=${config.pageSize}&SortBy=Product_Size_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: CORP_PRODUCTS_SIZE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_PRODUCTS_SIZE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORP_PRODUCTS_SIZE_FAIL,
      payload: err
    });
  }
};

/*=============================================================
              get All Approval Products Action
===============================================================*/
export const getAllApprovalProductsAction = (formData) => async dispatch => {
  try {
    dispatch({
      type: CORP_PRODUCTS_APPROVE_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Get_Admin?PageNo=1&PageSize=${config.pageSize}&SortBy=Product_Id&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CORP_PRODUCTS_APPROVE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_PRODUCTS_APPROVE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORP_PRODUCTS_APPROVE_FAIL,
      payload: err
    });
  }
};
/*=============================================================
             Update approved product Action
===============================================================*/
export const updateProductApprovalAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  dispatch({
    type: CORP_PRODUCTS_UPDATE_REQUEST
  });
  boomiAdminApi.put(`/Products/Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: CORP_PRODUCTS_UPDATE_SUCCESS
        });
        toast('Product has been Accepted.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch(paginationPendingProducts(filters, pagination, callBackPagination));
      } else {
        dispatch({
          type: CORP_PRODUCTS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CORP_PRODUCTS_UPDATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

/*=============================================================
              get All Approval Products Action
===============================================================*/


/*=============================================================
                  Get All Product Action
===============================================================*/
export const getAllProductsSearchAction = (ProductName) => async dispatch => {
  try {
    let formData = {
      Product_Name: ProductName,
      Records_Filter: "FILTER"
    };
    dispatch({
      type: CORP_PRODUCTS_FILTER_REQUEST
    });
    let { data } = await nodeGetApi.post(
      `/Products/Product_Admin_Get_Dropdown_Search?PageNo=1&PageSize=${config.pageSize}&SortBy=PRODUCT_NAME&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: CORP_PRODUCTS_FILTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CORP_PRODUCTS_FILTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CORP_PRODUCTS_FILTER_FAIL,
      payload: err
    });
  }
};

//Get Product
export const getProductAction = (formData, callBackProductsData) => async dispatch => {
  try {
    let { data } = await nodeGetApi.post(
      `/Products/Get_Admin`,
      formData
    );
    if (data) {
      callBackProductsData(data)
    }
  } catch (err) {
  }
};

/*=============================================================
                  Copy product Action
===============================================================*/
export const CopyProductAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(`/Product_Copy/Product_Create`,
      formData
    );
    if (data) {
      dispatch(paginationProductsCorp(filters, pagination, callBackPagination));
      toast('Copy Product created successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
  } catch (err) {
  }
};


export const updateProductRequestAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: CORP_PRODUCTS_REQUEST_UPDATE_REQUEST
  });
  nodeCorpMongoApi.post(`/corpSAProductRequest/update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.code === 200) {
        dispatch({
          type: CORP_PRODUCTS_REQUEST_UPDATE_SUCCESS
        });
        toast('Product Request updated successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/corporate-product-request-management');
      } else {
        dispatch({
          type: CORP_PRODUCTS_REQUEST_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: CORP_PRODUCTS_REQUEST_UPDATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working"
      });
    })
};