import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationCorpCouponHistoryGet } from 'src/store/actions/node-actions/nodepagination';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const CorpCouponHistoryListView = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useLocation();
    const { state } = location;

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);;

    const [corpcouponhistory, setCorpCouponHistory] = useState([]);
    const [filters, setFilters] = React.useState({
        Corp_Coupon_User_Id: state.id
    });
    const couponredeemhistory = corpcouponhistory[0]?.couponHistoryDetails;

    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ page: 1, limit: 10, sortBy: "createdAt:desc" })

    const handleLimitChange = event => {
        setPagination({ ...pagination, limit: +event.target.value, page: 1 });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, limit: pagination.limit, page: newPage + 1 });
    };

    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setCorpCouponHistory(data);
        }
        setPageOld(true);
        if (pagination) {
            pagination.sortBy = 'createdAt:desc'
            setPagination(pagination);
        } else {
            setPagination({ page: 1, limit: 10, sortBy: "createdAt:desc" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            corpcouponhistoryCall();
        }
    }, [pagination])

    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
            setTestFilters(filters);
            searchFunction();
        }
    }, [filters]);

    const searchFunction = () => {
        const searchpagination = {
            page: 1, limit: 10, sortBy: "createdAt:desc"
        }
        dispatch(paginationCorpCouponHistoryGet(filters, searchpagination, callBackPagination))
    }

    const corpcouponhistoryCall = () => {
        dispatch(paginationCorpCouponHistoryGet(filters, pagination, callBackPagination))
    };

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ page: 1, limit: pagination?.limit, sortBy: order === 'asc' ? "createdAt:asc" : "createdAt:desc" })
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    useEffect(() => {
        dispatch(paginationCorpCouponHistoryGet(filters, pagination, callBackPagination));
        dispatch(getRolePermissions(Login_Details));
    }, []);

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/corporate-coupon-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Coupon history
                    </Typography>
                </Box>
                <Toolbar state={state} corpcouponhistory={corpcouponhistory}/>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                    Redemption history
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Results
                        allCorpCouponHistory={corpcouponhistory}
                        couponredeemhistory={couponredeemhistory}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        pagination={pagination}
                        setPagination={setPagination}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default CorpCouponHistoryListView;