import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getAllPartners,
  getAllProducts,
  getAllMasters,
  getAllSizes,
  getAllDetails,
  updateNewInventoryAction
} from './../../store/actions/inventoryAction';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import { CircularProgress } from '@mui/material';
import { getAllPartnersAddress } from 'src/store/actions/productpartnerAction';

const statusOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];
const lineOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'ONLINE',
    label: 'ONLINE'
  },
  {
    value: 'OFFLINE',
    label: 'OFFLINE'
  },
  {
    value: 'BOTH',
    label: 'BOTH'
  },
];

const EditNewInventory = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = location;

  /// componentDidMount
  useEffect(() => {
    let formData = {
      Records_Filter: 'FILTER',
      Partner_Product_Id: state.Partner_Product_Id,
      Inventory_Id: state.Inventory_Id
    };
    let formData1 = {
      Records_Filter: 'FILTER',
      Product_Master_Id: state.Product_Master_Id,
      Partner_Details_Id: state.Partner_Details_Id
    };
    dispatch(getAllPartners(formData));
    dispatch(getAllProducts());
    dispatch(getAllDetails());
    dispatch(getAllMasters());
    const formDataAddrs = {
      Records_Filter: "FILTER",
      Partner_Details_Id: state.Partner_Details_Id,
      search: ''
    }
    dispatch(getAllPartnersAddress(formDataAddrs));
    dispatch(getAllSizes(formData1));
  }, []);


  const isSubmited = useSelector(
    state => state.inventory.inventoryUpdate.isLoading
  );
  const errorMessage = useSelector(
    state => state.inventory.inventoryUpdate.error
  );
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const partners = useSelector(
    state => state.inventory.inventoryPartners.partners
  );
  const address = useSelector(
    state => state.productPartner.partnerAddress.address
  );

  const sizes = useSelector(state => state.inventory.inventorySizes.sizes);

  return (
    <Formik
      initialValues={{
        Inventory_Id: state.Inventory_Id ? state.Inventory_Id : '',
        Partner_Address_Id: state.Partner_Address_Id
          ? state.Partner_Address_Id
          : '',
        Partner_Product_Id: state.Partner_Product_Id
          ? state.Partner_Product_Id
          : '',
        Offline_Min_Stock: state.Offline_Min_Stock
          ? state.Offline_Min_Stock
          : '',
        Product_Size_Id: state.Product_Size_Id ? state.Product_Size_Id : '',
        Locker_Number: state.Locker_Number ? state.Locker_Number : '',
        Availability_Status: state.Availability_Status
          ? state.Availability_Status
          : '',
        Availability_Stock: state.Availability_Stock
          ? state.Availability_Stock
          : '',
        Product_Master_Id: state.Product_Master_Id ? state.Product_Master_Id : "",
        Availability_On: state.Availability_On ? state.Availability_On : '',
        Modified_By: loginEmail
      }}
      validationSchema={Yup.object().shape({
        Availability_Stock: Yup.string().required(
          'Availability stock is required.'
        ),
        Offline_Min_Stock: Yup.string().required(
          'Offline Min Stock is required.'
        ),
        Partner_Product_Id: Yup.string().required(
          'Partner with Product Name is required.'
        ),
        Product_Size_Id: Yup.string().required('Product Size is required.'),
        Partner_Address_Id: Yup.string().required(
          'Partner Address is required.'
        ),
        Availability_On: Yup.string().required('Availability is required.'),
        Availability_Status: Yup.string().required(
          'Availability Status is required.'
        )
      })}
      onSubmit={values => {
        let formData = JSON.parse(JSON.stringify(values));
        dispatch(updateNewInventoryAction(formData, navigate));
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        // setFieldError, setErrors, setFieldTouched,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    {/* Partner Product Id */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled
                        label="Partner with Product Name"
                        name="Partner_Product_Id"
                        value={values.Partner_Product_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">
                          --Please Select--
                        </option>
                        {partners.map((option, i) => (
                          <option key={i} value={option.Partner_Product_Id}>
                            {option.Product_Name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    {/*Product Size*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Product Size"
                        name="Product_Size_Id"
                        value={values.Product_Size_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {sizes.map(option => (
                          <option
                            value={option.Product_Size_Id}
                            key={option.Product_Size_Id}
                          >
                            {option.Size + ' - ' + option.Size_Measurement}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Partner Address */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // disabled
                        label="Partner Address"
                        name="Partner_Address_Id"
                        value={values.Partner_Address_Id}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        <option key="" value="">
                          --Please Select--
                        </option>
                        {address.map((option, i) => (
                          <option key={i} value={option.Partner_Address_Id}>
                            {option.Location}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Availability Stock */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Availability_Stock &&
                          errors.Availability_Stock
                        )}
                        fullWidth
                        helperText={
                          touched.Availability_Stock &&
                          errors.Availability_Stock
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        label="Availability Stock"
                        name="Availability_Stock"
                        value={values.Availability_Stock}
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    {/* Offline Min Stock */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Offline_Min_Stock && errors.Offline_Min_Stock
                        )}
                        fullWidth
                        helperText={
                          touched.Offline_Min_Stock && errors.Offline_Min_Stock
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // onChange={(e) => {
                        //   const inputValue = Number(e.currentTarget.value);
                        //   const availableStock = Number(values.Availability_Stock);
                        //   if (inputValue <= availableStock && inputValue > 0) {
                        //     handleChange(e);
                        //     setErrors({});
                        //   } else {
                        //     setErrors({
                        //       Offline_Min_Stock: "Offline Min Stock should be less than or equal to Availability Stock",
                        //     });
                        //     setFieldTouched("Offline_Min_Stock", true, true);
                        //   }
                        // }}
                        type="number"
                        label="Offline Min Stock"
                        name="Offline_Min_Stock"
                        value={values.Offline_Min_Stock}
                        variant="outlined"
                      >

                      </TextField>
                    </Grid>
                    {/* Locker Number */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Locker_Number && errors.Locker_Number
                        )}
                        fullWidth
                        helperText={
                          touched.Locker_Number && errors.Locker_Number
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label=" Locker Number"
                        name="Locker_Number"
                        value={values.Locker_Number}
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    {/* Availability stock*/}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Availability_On && errors.Availability_On
                        )}
                        fullWidth
                        helperText={
                          touched.Availability_On && errors.Availability_On
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Availability On"
                        name="Availability_On"
                        select
                        SelectProps={{ native: true }}
                        value={values.Availability_On}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}

                      >
                        {lineOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {/* Availability Status */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.Availability_Status &&
                          errors.Availability_Status
                        )}
                        fullWidth
                        helperText={
                          touched.Availability_Status &&
                          errors.Availability_Status
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Availability Status"
                        name="Availability_Status"
                        select
                        SelectProps={{ native: true }}
                        value={values.Availability_Status}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}

                      >
                        {statusOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" pl={2}>
              <span style={{ color: 'red' }}>{errorMessage}</span>
            </Box>

            <Box display="flex" p={2}>
              {isSubmited ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={isSubmited}
                  type="submit"
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  {/* Save details */}
                  <span style={{ color: 'white' }}>Save details</span>
                </Button>
              )}
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EditNewInventory.propTypes = {
  className: PropTypes.string
};

export default EditNewInventory;
