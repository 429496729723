
import { Add, Remove, Delete, DoorbellSharp, FileCopy, Print, StarOutline, LocalShipping, ListAlt, ExitToApp, PersonOffOutlined, Person2, LogoutOutlined } from '@mui/icons-material'
import {
    Autocomplete, Badge, Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Divider, Drawer, FormControl, FormControlLabel, Grid, IconButton,
    Menu, MenuItem, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, CircularProgress
} from '@mui/material'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import isEmpty from 'src/store/validations/is-empty'
import { POS_Category_Get, POS_Cust_Get, POS_Product_Get, POS_Session_Update, POS_Customer_create, POS_Order_create, User_RecentOrder_Get, POS_Invoice_Get } from '../../../../store/actions/posActions'
import { nodeCudApi } from 'src/store/actions/commonAxios'

const ProductPos = () => {
    const navigate = useNavigate();
    const typoValueRef = useRef();
    const query = useLocation();
    const dispatch = useDispatch();
    const [objectJSON, setObjectJSON] = useState({})
    const [selectedProducts, setSelectedProducts] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const [categories, setCategories] = useState([])
    const [customers, setCustomers] = useState([])
    const [posProducts, setposProducts] = useState([])
    const [orgMrp, setOrgMrp] = useState(0);
    const [discntMrp, setDiscntMrp] = useState(0);
    const [blocks, setBlocks] = useState({ Flat_Discount: false })
    const [Flat_Discount_Percentage, setFlat_Discount_Percentage] = useState(0)
    const [Hold_Id, setHold_Id] = useState("")
    const location = useLocation();
    const { cashInHand, upiAmount, cardAmount, sessionID } = location.state || {};
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const [customerOrder, setCustomerOrder] = useState([])
    const [Amount_In_Hand, setAmount_In_Hand] = useState(0);
    const [Abnormal_Cash_In_Hand, setAbnormal_Cash_In_Hand] = useState(cashInHand)
    const [loading, setLoading] = useState(false);
    const [loadingOrder, setLoadingOrder] = useState(false);

    // const [IGST_Percentage, setIGST_Percentage] = useState(0)
    // const [Hold_Data, setHold_Data] = useState({})
    // const createData = (name, quantity, mrp, discount, netAmt) => ({
    //     name, quantity, mrp, discount, netAmt,
    // });
    // const [rows, setRows] = useState(initialRows);

    useEffect(() => {
        if (Boolean(cashInHand) === "Number")
            setAbnormal_Cash_In_Hand(Number(cashInHand))
    }, [cashInHand])

    const handleIncrement = (index) => {
        let updatedRows = [...selectedProducts];
        updatedRows[index].quantity += 1;
        updatedRows[index].Discount = (Number(updatedRows[index].Discount) / (updatedRows[index].quantity - 1)) * updatedRows[index].quantity;
        updatedRows[index].Partner_Selling_Price = (Number(updatedRows[index].Partner_Price) * updatedRows[index].quantity) - updatedRows[index].Discount;
        setSelectedProducts(updatedRows);
    };

    const handleDecrement = (index) => {
        let updatedRows = [...selectedProducts];
        if (updatedRows[index].quantity > 1) { // Ensure quantity doesn't go below 1
            updatedRows[index].quantity -= 1;
            updatedRows[index].Discount = (Number(updatedRows[index].Discount) / (updatedRows[index].quantity + 1)) * updatedRows[index].quantity;
            updatedRows[index].Partner_Selling_Price = (Number(updatedRows[index].Partner_Price) * updatedRows[index].quantity) - updatedRows[index].Discount;
            setSelectedProducts(updatedRows);
        }
    };

    const handleDelete = (id = "") => {
        setSelectedProducts(state => {
            const data = [...state]
            const newData = data.filter(value => value.Product_Size_Id != id)
            return newData
        })
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const Get_Hold_Packets = useMemo(() => {
        try {
            if (window.localStorage.getItem("Hold_Packets")) {
                const Total_Packets = JSON.parse(window.localStorage.getItem("Hold_Packets"))?.Hold_Packets || 0
                let Last_Hold_Id = Total_Packets?.reverse()[0].Hold_Id
                let Last_Id_Number = Number(Last_Hold_Id.slice("H-".length, Last_Hold_Id?.length)) + 1
                return { Packets_Length: Total_Packets?.length || 0, ALL_HOLD_PACKETS: Total_Packets.sort((a, b) => Number(a.Hold_Id.slice("H-".length, a.Hold_Id?.length) - Number(b.Hold_Id.slice("H-".length, b.Hold_Id?.length)))) || [], New_Hold_Id: "H-" + Last_Id_Number.toString().padStart(6, "0") }
            }
            else
                throw new Error("No holds till now....")
        }
        catch (err) {
            return { Packets_Length: 0, ALL_HOLD_PACKETS: [], New_Hold_Id: "H-000001" }
        }
    }, [window.localStorage.getItem("Hold_Packets")])

    // const [dropdownValue, setDropdownValue] = React.useState('');

    // const handleDropdownChange = (event) => {
    //     setDropdownValue(event.target.value);
    // };

    const toPOSOrders = () => {
        navigate('/app/pos-order-management');
    };

    //for register popup
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    //for customer create popup
    const [newOpen, setNewOpen] = useState(false);

    const handleClickNewOpen = () => {
        setNewOpen(true);
    };

    const handleNewClose = () => {
        setNewOpen(false);
        setMobileNumber('');
        setOtp('');
        setName('');
        setEmail('');
        setDob('');
        setOtpVerify(true);
        setIsOtpSent(false)
    };

    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    // const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setBlocks(state => ({ ...state, Flat_Discount: !state.Flat_Discount }))
    };

    const [dropdownCatValue, setDropdownCatValue] = useState(null);
    const [categoryId, setCategoryId] = useState(null);

    const handleAutocompleteChange = (event, newValue) => {
        setDropdownCatValue(newValue);
        if (newValue) {
            setCategoryId(newValue.Category_Id); // Step 2: Update the state
            const formData = {
                "Category_Id": newValue.Category_Id,
                "search": searchValue
            };
            POS_Prod_Get_Call(formData)
        }
    };

    // const [dropdownCustValue, setDropdownCustValue] = useState(null);

    // const handleCustChange = (event, newValue) => {
    //     setDropdownCustValue(newValue);
    //     if (newValue) {
    //         const formData = {
    //             "search": searchValue
    //         };
    //         POS_Customer_Get_Call(formData)
    //     }
    // };

    // for calculating the each currencies total

    const handleInputChange = (event, from = 0, index = 0) => {
        const value = parseInt(event.target.value, 10) || 0;
        setObjectJSON(state => {
            let oldArray = state
            const newObjcet = {
                value: from,
                qty: value,
                // subTotal :  value * from
            }
            oldArray[index] = newObjcet
            return oldArray
        })
    };

    const totalSum = useMemo(() => {
        let sum = 0
        let array = Object.values(objectJSON)
        for (let i = 0; i < array.length; i++) {
            sum += (Number(array[i].value) * (Number(array[i].qty)))
        }
        return sum
    }, [Object.values(objectJSON)])

    const [currentDateTime, setCurrentDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');

    useEffect(() => {
        const now = new Date();
        const formattedCurrentDateTime = now.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        const endOfDay = new Date(now);
        endOfDay.setHours(23, 59, 0, 0); // Set time to 11:59 PM

        const formattedEndDateTime = endOfDay.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        setCurrentDateTime(formattedCurrentDateTime);
        setEndDateTime(formattedEndDateTime);
    }, []);

    // const [dropdownCatValue, setDropdownCatValue] = useState(null);

    useEffect(() => {
        POS_Category_Get_Call();
        POS_Prod_Get_Call();
        POS_Customer_Get_Call();
    }, [])

    const POS_Category_Get_Call = async () => {
        const response = await POS_Category_Get({})
        if (response.status) {
            setCategories(response.data)
        } else {
            setCategories([])
        }
    }

    const POS_Customer_Get_Call = async (formData = {}) => {
        const response = await POS_Cust_Get(formData)
        if (response.status) {
            setCustomers(response.data)
        } else {
            setCustomers([])
        }
    }

    const POS_Prod_Get_Call = async (formData = {}) => {
        try {
            const response = await POS_Product_Get(formData);
            if (response.status) {
                setposProducts(response.data);
            } else {
                setposProducts([]);
            }
        } catch (error) {
            setposProducts([]);
        }
    };

    const Get_IGST = useMemo(() => {
        let IGST = 0
        selectedProducts.map((value) => {
            IGST = IGST + Number(value?.Igst_Percentage)
        })

        return IGST
    }, [selectedProducts])

    // const [invoiceDetails, setInvoice] = useState([]);

    const Invoice_Get = async (orderId) => {
        let formData = {
            Order_Id: orderId,
        };
        try {
            const response = await POS_Invoice_Get(formData);
            if (response?.status) {
                // setInvoice(response.data);
                setTimeout(() => { InvoicePrint(response.data) }, 2000)
            } else {
                // setInvoice([]);
            }
        } catch (error) {
            // setInvoice([]);
        }
    };

    const InvoicePrint = (invoiceDetails = {}) => {
        // Create a new window for printing
        let printWindow = window.open('', '_blank');
        // Write the print contents and styles to the new window
        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write(`<style> .page-break { page-break-after: always; } 
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 2px;
        }
        th, td {
            border: 1px solid #000;
            padding: 2px;
        }
        th {
            background-color: #f2f2f2;
        }
        .container {
            display: flex;
            justify-content: space-between;
            margin: 2px;
        }
        .left,
        .right {
            flex: 1;
            text-align: left; /* Adjust alignment as needed */
        }
        .right {
            text-align: right; /* Adjust alignment as needed */
            margin-top: -18px;
        }
        div p {
            margin: 0.5; /* Remove default margin */
            font-weight: bold;
        }
        div p span:first-child {
            font-weight: bold; /* Make the first span (inside p) bold */
        }
        div p .normal-text {
            font-weight: normal; /* Make the second span (inside p) normal */
        }
        div p span {
            display: inline-block; /* Display spans inline */
        }
        </style>`);
        printWindow.document.write('</head><body onclick="window.print()" >');
        printWindow.document.write(
            `<div>
             <span style="text-align:center">
                <div><p><span><span>Tax Invoice</span></p></div>
             </span>
             <div class="container">
             <span class="left">
                <img height="40px" src= "/static/meepaisalogo_bw.jpg" alt="Logo"></img>
                <div><p><span>Sold By(Seller Partner Address):</span></p></div>
                <div>meepaisa</div>
                <div>Plot No.5B, Ground Floor, Regent Gateway</div>
                <div>ITPL Main Rd, beside Advaith Hyundai Service Center</div>
                <div>Whitefield, Bengaluru, Karnataka</div>
                <div>India</div>
                <div>560037</div>
                <div><p><span>Order Details:</span></p></div>
                <p><span>Order Number:</span>`+ invoiceDetails?.Order?.Order_Id + `</p>
                <p><span>Order Date:</span>`+ invoiceDetails?.Order?.Created_Date + `</p>
                <p><span>Invoice Number:</span>`+ invoiceDetails?.Order?.Invoice_No + `</p>
                <p><span>Invoice Date:</span>`+ invoiceDetails?.Order?.Created_Date + `</p>
                <p><span>PAN Number:</span> AAECE4620R</p>
                <p><span>GSTIN:</span> 29AAECE4620R1Z5</p>
             </span>
         
             </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th >Product Name</th>
                    <th >Quantity</th>
                    <th >Discount</th>
                    <th >GST</th>
                    <th >MRP</th>
                    <th >Price</th>
                </tr>
            </thead>
            <tbody>`);

        let prodData = invoiceDetails?.Itemdetails?.map((prod, index) => {
            // Check if either Discount_A or Discount_P is present
            if (prod.Discount_A) {
                printWindow.document.write(`<tr>
                            <th>${prod.Product_Name}</th>
                            <th>${prod.Quantity}</th>
                            <th>${prod.Discount_A}</th>
                            <th>
                                CGST: ${prod.Cgst}<br />
                                SGST: ${prod.Sgst}
                            </th>
                            <th>${prod.Mrp}</th>
                            <th>${prod.Base_Selled_Amount}</th>
                        </tr>`);
            }
            else {
                printWindow.document.write(`<tr>
                  <th>${prod.Product_Name}</th>
                  <th>${prod.Quantity}</th>
                  <th>${prod.Discount_P}</th>
                  <th>
                      CGST: ${prod.Cgst}<br />
                      SGST: ${prod.Sgst}
                  </th>
                  <th>${prod.Mrp}</th>
                  <th>${prod.Base_Selled_Amount}</th>
              </tr>`);
            }
        });

        printWindow.document.write(`
        </tbody>
        </table>
        <div style="margin-top: 2px;, margin-bottom: 2px">
        <div><p><span> Total paid: `+ invoiceDetails?.Order?.Payment_Amount + `</span></p></div>
        <div><p><span> Discounted Amount: `+ invoiceDetails?.Order?.Discount + `</span></p></div>
        </div>
        <div class="container" style="margin-top: 2px;, margin-bottom: 2px">
        <span class="left">
        <img height="40" src="/static/invoice_signature.png"></img>
        <div><p><span> Authorized Signatory</span></p></div>
        </span>
        <span class="right">
        <img height="120" src="/static/terms_cond_qr.png"></img>
        </span>
        </div>
        <div style="margin-top: 2px;, margin-bottom: 2px"><p><span>Note: If Undelivered return to Seller Partner Address.</span></p></div>
        </div>`);
        // printWindow.document.write(printContents);
        printWindow.document.write('</body></html>');

        // Print the contents
        // printWindow.print();
        // Close the window
        // printWindow.close();
    }

    const handleTableMemo = useMemo(() => {
        if (!isEmpty(selectedProducts)) {
            setOrgMrp(0)
            setDiscntMrp(0)
            return (
                <TableContainer sx={{ justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
                    <TableHead sx={{ height: '36px', width: '100%', backgroundColor: '#E5F6FD', alignContent: 'space-evenly' }} >
                        <TableRow sx={{ height: '36px', backgroundColor: '#E5F6FD', alignContent: 'space-evenly' }}>
                            <TableCell sx={{ fontSize: '14px', fontWeight: 500 }}>Item name</TableCell>
                            <TableCell sx={{ fontSize: '14px', fontWeight: 500 }} align="center">Quantity</TableCell>
                            <TableCell sx={{ fontSize: '14px', fontWeight: 500 }} align="center">MRP</TableCell>
                            <TableCell sx={{ fontSize: '14px', fontWeight: 500 }} align="center">Discount</TableCell>
                            <TableCell sx={{ fontSize: '14px', fontWeight: 500 }} align="center">Net amt</TableCell>
                            <TableCell sx={{ fontSize: '14px', fontWeight: 500 }} align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ alignContent: 'space-evenly', width: '100%' }}>
                        {selectedProducts?.map((row, index) => {
                            setOrgMrp(state => (state + (Number(row.Partner_Price) * Number(row.quantity))))
                            setDiscntMrp(state => (state + Number(row.Partner_Selling_Price)))
                            return (
                                <SelectedProductsTable index={index} key={index} row={row} handleDelete={handleDelete} handleIncrement={handleIncrement} handleDecrement={handleDecrement} setSelectedProducts={setSelectedProducts} blocks={blocks} />
                            )
                        })}
                    </TableBody>
                </TableContainer>
            )
        } else {
            setOrgMrp(0)
            setDiscntMrp(0)
            return (
                <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <Typography sx={{ fontSize: "1.3rem" }}>
                        Start adding products to continue 👉
                    </Typography>
                </Box>
            )

        }
    }, [selectedProducts, blocks])


    const Get_Flat_Discount_Amount = useMemo(() => {
        try {
            let Tax_Amount = 0
            let Final_Amount = 0
            Tax_Amount = (Get_IGST / 100) * (Number(orgMrp)).toFixed(2) || 0

            if (blocks.Flat_Discount) {
                const Flat_Discount_Amount = (Flat_Discount_Percentage * Number(orgMrp)) / 100
                Final_Amount = (Number(orgMrp) - Flat_Discount_Amount).toFixed(2)
                // Final_Amount = ((Number(orgMrp) - Flat_Discount_Amount) + Tax_Amount).toFixed(2)
                // return { MRP: (Number(orgMrp) - Flat_Discount_Amount).toFixed(2) || 0, DISCNT: Flat_Discount_Amount.toFixed(2) }
                return { MRP: (Number(orgMrp)).toFixed(2) || 0, DISCNT: Flat_Discount_Amount.toFixed(2), FLAT_AMOUNT: (Number(orgMrp) - Flat_Discount_Amount).toFixed(2) || 0, Tax_Amount: Tax_Amount.toFixed(2), Final_Amount }
            }
            else {
                // Final_Amount = (Number(discntMrp) + Tax_Amount).toFixed(2)
                Final_Amount = (Number(discntMrp)).toFixed(2)
                return { MRP: Number(orgMrp).toFixed(2), DISCNT: (Number(orgMrp) - Number(discntMrp)).toFixed(2) || 0, FLAT_AMOUNT: Number(discntMrp).toFixed(2), Tax_Amount: Tax_Amount.toFixed(2), Final_Amount }
            }
        }
        catch {
            return { MRP: 0, DISCNT: 0, Tax_Amount: 0, Final_Amount: 0 }
        }
    }, [orgMrp, Flat_Discount_Percentage, blocks, discntMrp])

    useEffect(() => {
        const data = query.search
        const value = data.slice("?Hold_Id=".length, data.length).toString()
        if (value && Get_Hold_Packets.ALL_HOLD_PACKETS?.length > 0) {

            const Packet = Get_Hold_Packets.ALL_HOLD_PACKETS?.filter(packet => {
                return (packet.Hold_Id === value)
            })[0]
            if (Boolean(Packet)) {
                setHold_Id(value)
                setSelectedProducts(Packet?.Products || [])
                setAmount_In_Hand(Packet?.Cash_In_Hand) // define useState its not define any where
            } else {
                setSelectedProducts([])
                setHold_Id(Get_Hold_Packets.New_Hold_Id)
                navigate(query.pathname)
            }
        }
        else {
            setSelectedProducts([])
            setHold_Id(Get_Hold_Packets.New_Hold_Id)
            setAmount_In_Hand(Abnormal_Cash_In_Hand) // for inital amount in hand 
        }

    }, [query, Get_Hold_Packets, Abnormal_Cash_In_Hand])

    const [cashRcvd, setCashRcvd] = useState('');
    const [sum, setSum] = useState(0);
    const [cashLeft, setCashLeft] = useState('');
    const [closingNote, setClosingNote] = useState('');

    const [error, setError] = useState({ cashRcvd: false, cashLeft: false, closingNote: false });

    const handleCashRcvd = (event) => {
        const value = Number(event.target.value);
        setCashRcvd(event.target.value);
        setError((prevError) => ({ ...prevError, cashRcvd: false }));
        setSum(Number(cashInHand) + value + cardAmount + upiAmount);
    };

    const handleCashInHandChange = (event) => {
        const value = Number(event.target.value);
        setCashLeft(event.target.value);
        setError((prevError) => ({ ...prevError, cashLeft: false }));
        // setSum(value + Number(cashRcvd));
    };

    const handleclosingNote = (event) => {
        setClosingNote(event.target.value);
        setError((prevError) => ({ ...prevError, closingNote: false }));
    };

    const handleSubmit = async () => {
        // Convert input values to numbers
        setLoading(true);
        const cashRcvddNumber = Number(cashRcvd);
        const cashLeftNumber = Number(cashLeft);
        const closingNoteValid = closingNote !== '';
        // Validate input values
        const isCashRcvdValid = !isNaN(cashRcvddNumber) && cashRcvddNumber > 0;
        const isCashLeftNoValid = !isNaN(cashLeftNumber) && cashLeftNumber > 0;

        // Set error state and exit if validation fails
        if (!isCashRcvdValid || !isCashLeftNoValid || !closingNoteValid) {
            setError({
                cashRcvd: !isCashRcvdValid,
                cashLeft: !isCashLeftNoValid,
                closingNote: !closingNoteValid
            });
            setLoading(false);
            return; // Exit the function if validation fails
        }

        const formData = {
            "Pos_Session_Id": sessionID,
            "Amount_Recived": cashRcvddNumber,
            "Amount_Left_In_Drawer": cashLeftNumber,
            "Closing_Note": closingNote,
            "Currency_Count": objectJSON,
            "Cash_Payment": cashRcvddNumber,
            "Modified_By": loginEmail
        };

        // Dispatch the action and wait for the result
        const result = await dispatch(POS_Session_Update(formData));
        setLoading(false);
        if (result.status) {
            // Only navigate if the dispatch is successful
            localStorage.removeItem("Hold_Packets")
            navigate('/app/pos-order-management');
        } else {
            toast('Failed to update session. Please try again.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
        setLoading(false);

    };

    const [mobileNumber, setMobileNumber] = useState('');
    const [errorMobNo, setErrorMobNo] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setMobileNumber(value);
        }
        if (value.length !== 10) {
            setErrorMobNo(true);
        } else {
            setErrorMobNo(false);
        }
    };

    const [otp, setOtp] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [otpVerify, setOtpVerify] = useState(true);

    const handleCustCreate = async () => {
        const formData = {
            "Name": name,
            "Email_ID": email,
            "Mobile": '+91 ' + mobileNumber,
            "Mobile_OTP": otp,
            "Dob": dob
        };

        const result = await dispatch(POS_Customer_create(formData));
        // Check if the dispatch was successful
        if (result.status) {
            handleNewClose();
        } else {
            toast(result.data.UI_Display_Message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };


    const handleGetOtp = async () => {
        if (!mobileNumber) {
            toast('Please enter a valid mobile number.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            return;
        }
        try {
            const response = await nodeCudApi.post('/POS/Mobile_OTP_Send', { Mobile: '+91 ' + mobileNumber });
            if (response.data.Response_Status == 'Success') {
                setIsOtpSent(true);
                toast('OTP sent successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            } else {
                toast('Failed to send OTP. Please try again.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            }
        } catch (error) {
            toast('Error sending OTP: ' + error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        }
    };

    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedCustomerName, setSelectedCustomerName] = useState(null);
    const [custError, setCustError] = useState(false); // To handle validation error display

    const handleCustChange = (event, newValue) => {
        if (newValue) {
            setSelectedCustomerId(newValue.User_Details_Id); // Set the User_Details_Id of the selected customer
            setSelectedCustomerName(newValue.Full_Name);
            setCustError(false);
            setTimeout(async () => { // Make the callback function async
                const formData = {
                    User_Details_Id: newValue.User_Details_Id, // Use newValue to get the correct ID
                };

                try {
                    // Wait for the response from the API call
                    const response = await User_RecentOrder_Get(formData);
                    if (response.status) {
                        setCustomerOrder(response?.data);
                    } else {
                        setCustomerOrder([]);
                    }
                } catch (error) {
                    setCustomerOrder([]);
                }
            }, 2000); // Keep the delay as needed
        } else {
            setSelectedCustomerId(null); // Reset if no customer is selected
            setCustError(true);
        }
    };


    const [payType, setPayType] = useState('');
    const [payTypeError, setPayTypeError] = useState(false); // To handle validation error display

    const handlePayTypeChange = (event) => {
        setPayType(event.target.value);
        setPayTypeError(false);
    };

    const handleCreateOrder = async () => {
        setLoadingOrder(true);
        if (!payType || !selectedCustomerId) {
            setPayTypeError(!payType); // Show error if payType is missing
            setCustError(!selectedCustomerId); // Show error if customer ID is missing
            setLoadingOrder(false);
            return;
        }

        const orderData = {
            Payment_Amount: Get_Flat_Discount_Amount.FLAT_AMOUNT,
            Created_By: loginEmail,
            Pos_By: loginEmail,
            Payment_Type: payType,
            User_Details_Id: selectedCustomerId,
            Pos_Session_Id: sessionID,
            Total_Order_Value: Get_Flat_Discount_Amount.MRP,
            Discount: Get_Flat_Discount_Amount.DISCNT,
            // flatDiscount: Flat_Discount_Percentage,
            Payment_Transaction_Id: "",
            Payment_Transaction_Status: "",
            Partner_Address_Id: "",
            Order_Item: [
                ...selectedProducts.map((value) => {
                    if (value.Discount_Type == 'P') {
                        return ({
                            Quantity: value.quantity,
                            Discount_P: value.Discount,
                            Mrp: value.Partner_Price,
                            Net_Price: value.Partner_Selling_Price,
                            // Discount_A: "string", 
                            Base_Selled_Amount: value.Partner_Selling_Price,
                            Product_Id: value.Product_Id,
                            Partner_Product_Id: value.Partner_Product_Id,
                            Product_Size_Id: value.Product_Size_Id
                        })
                    }
                    else {
                        return (
                            {
                                Quantity: value.quantity,
                                // Discount_P: '', 
                                Mrp: value.Partner_Price,
                                Net_Price: value.Partner_Selling_Price,
                                Discount_A: value.Discount,
                                Product_Id: value.Product_Id,
                                Base_Selled_Amount: value.Partner_Selling_Price,
                                Partner_Product_Id: value.Partner_Product_Id,
                                Product_Size_Id: value.Product_Size_Id
                            })
                    }
                })]
        };

        try {
            const result = await dispatch(POS_Order_create(orderData));
            setLoadingOrder(false);
            if (result.status) {
                navigate('/app/pos-order-management');
            } else {
                toast.error('Failed to update session. Please try again.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            }
        } catch (error) {
            toast.error('An error occurred while creating the order. Please try again.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };


    return (
        <Box sx={{ width: "100%", height: "auto", backgroundColor: 'white', display: "flex", justifyContent: "center", }} >
            <Box sx={{ width: "100%", minHeight: "100vh", backgroundColor: "transparent", display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                <Box sx={{ width: "100%", backgroundColor: 'whitesmoke', display: "flex", flexDirection: "row", justifyContent: "flex-start" }} >

                    <Autocomplete
                        options={customers}
                        getOptionLabel={(option) => option.Full_Name + ',' + option.Mobile}
                        renderOption={(props, option,) => {
                            return (

                                <>
                                    <MenuItem {...props} key={option}>
                                        {option.Full_Name + ',' + option.Mobile}
                                    </MenuItem>
                                    {customers[customers.length - 1]?.User_Details_Id === option?.User_Details_Id &&
                                        <MenuItem {...props} key={option}>
                                            <Button onClick={(e) => {
                                                e.stopPropagation(); // Prevents the selection of the last item
                                                handleClickNewOpen();
                                            }} fullWidth>
                                                Add New Customer
                                            </Button>
                                        </MenuItem>}
                                </>

                            )
                        }}
                        // value={dropdownCatValue}

                        onChange={handleCustChange}
                        sx={{ width: '400px', marginBottom: '10px' }}
                        renderInput={(params) => <TextField {...params} error={custError}
                            helperText={custError ? 'Please select a customer' : ''}
                            onChange={(e) => { POS_Customer_Get_Call({ Search: e.currentTarget.value }) }}
                            label="Select Customer" variant="outlined" />}
                    />

                    <Typography ml={'10px'} mt={'17px'} sx={{ fontSize: '20px', fontWeight: 400 }}>Amount in hand : {Number(Amount_In_Hand)} /-</Typography>

                    <IconButton onClick={toPOSOrders} sx={{ marginLeft: '20px', marginRight: '20px' }} color="primary" component="span">
                        <LocalShipping />
                    </IconButton>
                    <IconButton onClick={toggleDrawer(true)} sx={{ marginLeft: '20px', marginRight: '20px' }} color="primary" component="span">
                        <ListAlt />
                    </IconButton>
                    <IconButton onClick={handleClick} sx={{ marginLeft: '20px', marginRight: '20px' }} color="primary" component="span">
                        <ExitToApp />
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={toPOSOrders}><IconButton sx={{ marginLeft: '20px', marginRight: '20px' }} color="primary" component="span">
                            <Person2 />
                        </IconButton> Back to Dashboard</MenuItem>
                        <MenuItem onClick={handleClickOpen}><IconButton sx={{ marginLeft: '20px', marginRight: '20px' }} color="primary" component="span">
                            <LogoutOutlined />
                        </IconButton> Close Register</MenuItem>
                    </Menu>

                </Box>

                <Paper elevation={3} style={{ padding: '16px', marginTop: '20px', }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                {handleTableMemo}
                                {/* <Box border={1} padding={2} height="100%">
                                    Left Grid Content
                                </Box> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* Right Grid Content */}
                                <Box sx={{ width: '100%', padding: 2 }}>
                                    {/* Dropdown */}
                                    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                                        <Autocomplete
                                            options={categories}
                                            getOptionLabel={(option) => option.Category_Name}
                                            value={dropdownCatValue}
                                            onChange={handleAutocompleteChange}
                                            renderInput={(params) => <TextField {...params} label="Select Category" variant="outlined" />}
                                        />
                                    </FormControl>

                                    {/* Search Bar */}
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Search for products..."
                                        sx={{ mb: 2 }}
                                        value={searchValue}
                                        onChange={(e) => {
                                            const formData = {
                                                "Category_Id": categoryId,
                                                "Search": e.currentTarget.value
                                            };
                                            setSearchValue(e.currentTarget.value)
                                            POS_Prod_Get_Call(formData)
                                        }}
                                    />

                                    {/* Cards */}
                                    <Grid container spacing={2} sx={{ maxHeight: "70vh", overflowY: "scroll" }} >
                                        {posProducts.length > 0 ? (
                                            posProducts.map((item, index) => (
                                                <Grid
                                                    key={index}
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    onClick={() => {
                                                        setSelectedProducts(state => {
                                                            const oldArray = state;
                                                            const isExistsInArray =
                                                                oldArray.filter(ele => ele?.Product_Id === item?.Product_Id && ele?.Product_Size_Id === item?.Product_Size_Id).length > 0;
                                                            let newArray = [];
                                                            if (!isExistsInArray) {
                                                                newArray = [...oldArray, { ...item, quantity: 1 }];
                                                                return newArray;
                                                            }
                                                            return state;
                                                        });
                                                    }}
                                                >
                                                    <Card
                                                        sx={{
                                                            width: "160px",
                                                            height: "164px",
                                                            borderRadius: "4px",
                                                            borderWidth: "1px",
                                                            marginBottom: "10px"
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Box sx={{ position: "relative" }}>
                                                                <Badge
                                                                    sx={{
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        right: 0,
                                                                        backgroundColor: "red",
                                                                        color: "white", // Optional for better visibility
                                                                        fontWeight: "bold",
                                                                        fontSize: "12px", // Adjust as needed
                                                                        padding: "4px 8px", // Add padding for shape
                                                                        borderRadius: "12px", // Makes it curved
                                                                        transform: "translate(50%, -50%)",
                                                                    }}
                                                                >
                                                                    <span>{item.Availability_Stock}</span>
                                                                </Badge>

                                                                <Box
                                                                    component="img"
                                                                    src={item.Product_Image}
                                                                    alt="Sample Image"
                                                                    sx={{
                                                                        width: "100px",
                                                                        height: "100px"
                                                                    }}
                                                                />
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {item.Product_Name + "-" + item.Size}
                                                                </Typography>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography marginTop={'10px'} marginLeft={'12px'} variant="h7" align="center" alignSelf={'center'} color="text.secondary">
                                                Product is unavailable..
                                            </Typography>
                                        )}

                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>

                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    {/* First Block */}
                    <Grid item xs={6} sx={{ alignItems: 'center' }}>
                        <Box padding={2} border={1} borderColor="grey.300" borderRadius={1}>
                            <Grid container spacing={2} alignItems={'center'} alignSelf={'center'}>
                                <Grid item xs={3} alignItems={'center'} alignSelf={'center'} >
                                    <Typography variant="h6" >{selectedProducts.length}</Typography>
                                    <Typography variant="body1" >Items</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                                <Grid item xs={3} alignItems={'center'} alignSelf={'center'}>
                                    <Typography variant="h6">   {Get_Flat_Discount_Amount.MRP}</Typography>
                                    <Typography variant="body1" >Total MRP</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                                <Grid item xs={2} alignItems={'center'} alignSelf={'center'}>
                                    <Typography variant="h6" >   {Get_Flat_Discount_Amount.DISCNT}</Typography>
                                    <Typography variant="body1" >Discount</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                                <Grid item xs={2} alignItems={'center'} alignSelf={'center'} >
                                    <Typography variant="h6" >{Get_Flat_Discount_Amount.Tax_Amount}</Typography>
                                    <Typography variant="body1" >Tax </Typography>
                                </Grid>

                                <Grid item xs={6} sx={{ flexDirection: 'row' }}>
                                    <FormControlLabel
                                        disabled={selectedProducts.length <= 0}
                                        control={<Checkbox checked={blocks.Flat_Discount} onChange={handleCheckboxChange} />}
                                        label="Flat discount %"
                                    />
                                    <TextField
                                        placeholder="0"
                                        size="small"
                                        sx={{ width: '80px', marginLeft: 1 }}
                                        onChange={(e) => {
                                            setFlat_Discount_Percentage(Number(e.currentTarget.value.replace(/\D/g, "")))
                                        }}
                                        disabled={!blocks.Flat_Discount} // Disable the TextField when the checkbox is not checked
                                    />
                                </Grid>

                                {/* <Grid item xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Round off"
                                    />
                                    <TextField size="small" defaultValue="0.00" sx={{ width: '100px' }} />
                                </Grid> */}

                                {/* <Grid item xs={6}>
                                    <TextField label="Due amount" size="small" defaultValue="0" sx={{ width: '100%' }} />
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Loyalty points"
                                    />
                                    <TextField size="small" defaultValue="20" sx={{ width: '80px' }} />
                                </Grid> */}

                                {/* <Grid item xs={4}>
                                    <Button variant="contained" color="primary" fullWidth>APPLY COUPON</Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button variant="contained" color="secondary" fullWidth>REDEEM CREDITS</Button>
                                </Grid> */}
                                <Grid item xs={4}>
                                    <Button
                                        disabled={selectedProducts.length <= 0}
                                        sx={{ backgroundColor: 'grey' }}
                                        variant="contained"
                                        fullWidth
                                        onClick={() => {
                                            const Create_Packet = {
                                                Hold_Id: Hold_Id,
                                                Products: selectedProducts,
                                                Total_MRP: Get_Flat_Discount_Amount.MRP,
                                                Discount_Issued: Get_Flat_Discount_Amount.DISCNT,
                                                Flat_Amount: Get_Flat_Discount_Amount.FLAT_AMOUNT,
                                                Contact_Name: selectedCustomerName,
                                                Created_On: new Date().toISOString(),
                                                Cash_In_Hand: cashInHand
                                            };

                                            let New_Storage = [...(Get_Hold_Packets.ALL_HOLD_PACKETS)];
                                            let index = New_Storage.findIndex(element => element.Hold_Id === Hold_Id);

                                            if (index !== -1) {
                                                New_Storage[index] = Create_Packet;
                                            } else {
                                                New_Storage.push(Create_Packet);
                                            }

                                            window.localStorage.setItem("Hold_Packets", JSON.stringify({ Hold_Packets: New_Storage }));
                                            navigate(query.pathname)
                                        }}
                                    >
                                        HOLD
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    {/* Second Block */}
                    <Grid item xs={3}>
                        <Box padding={2} border={1} borderColor="grey.300" borderRadius={1} textAlign="center">
                            <Typography variant="h6">Payment mode</Typography>
                            <TextField value={payType} error={payTypeError} // Shows error state if payment type is not selected
                                helperText={payTypeError ? 'Please select a payment type' : ''}
                                onChange={handlePayTypeChange} label="Select pay type" select size="small" fullWidth sx={{ marginBottom: 2 }}>
                                <MenuItem value="CASH">Cash</MenuItem>
                                <MenuItem value="UPI">UPI</MenuItem>
                                <MenuItem value="CARD">Card</MenuItem>
                                {/* <MenuItem value="Multipay">Multipay</MenuItem>
                                <MenuItem value="Pay later">Pay later</MenuItem> */}
                            </TextField>

                            <Typography variant="h4" color="#2196F3">{Get_Flat_Discount_Amount.Final_Amount}</Typography>
                            <Typography variant="h5" color="#2196F3">Amount due</Typography>

                            <Divider sx={{ my: 2 }} />

                            <Button onClick={handleCreateOrder} variant="contained" sx={{ backgroundColor: '#27b6cc' }} disabled={(selectedProducts.length <= 0) && loadingOrder} fullWidth>
                                {/* PROCEED TO COLLECT */}
                                {loadingOrder ? (
                                    <CircularProgress size={24} style={{ color: 'white' }} />
                                ) : (
                                    <span style={{ color: 'white' }}>PROCEED TO COLLECT</span>
                                )}
                            </Button>
                        </Box>
                    </Grid>

                    {/* Third Block */}
                    <Grid item xs={3}>
                        <Box padding={2} border={1} borderColor="grey.300" borderRadius={1}>
                            <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Customer details</Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400 }} >Total Order amount: <strong>{customerOrder[0]?.Total_Order_Value}</strong></Typography>
                            {/* <Typography sx={{ fontSize: '16px', fontWeight: 400 }} >Most purchased: <strong>Milk</strong></Typography> */}
                            <Typography sx={{ fontSize: '16px', fontWeight: 400 }} >Total purchase: <strong>{customerOrder[0]?.Total_Order_Value}</strong></Typography>

                            <Divider sx={{ my: 2 }} />

                            <Typography variant="body2">Last bill no: <strong>{customerOrder[0]?.Invoice_No}</strong></Typography>
                            <Typography variant="body2">Last bill amount: <strong>{customerOrder[0]?.Payment_Amount}</strong></Typography>
                            {customerOrder?.length > 0 ? <Button onClick={() => Invoice_Get(customerOrder[0]?.Order_Id)} variant="contained" fullWidth sx={{ mt: 2, backgroundColor: '#27b6cc' }}>PRINT LAST BILL</Button>
                                :
                                <Button disabled variant="contained" fullWidth sx={{ mt: 2, backgroundColor: 'grey' }}>PRINT LAST BILL</Button>
                            }
                        </Box>
                    </Grid>
                </Grid>

            </Box>

            {/* for register close - pos */}

            <Dialog open={open} onClose={handleClose} maxWidth="1100px">
                {/* <DialogTitle>Register</DialogTitle> */}
                <DialogContent sx={{ height: 'auto', maxWidth: "1050px" }}>
                    {/* <Box p={4}> */}
                    <Typography variant="h6" gutterBottom>
                        Current register ({currentDateTime}) - ({endDateTime})
                    </Typography>

                    <Grid container spacing={10}>
                        {/* Left Column */}
                        <Grid item xs={4}>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" mr={10}>
                                    Opening Cash
                                </Typography>
                                <TextField
                                    sx={{ width: '100px' }}
                                    value={cashInHand}
                                    variant="outlined"
                                    margin="normal"
                                    type={'number'}
                                    InputProps={{
                                        sx: {
                                            '& input::-webkit-outer-spin-button': {
                                                WebkitAppearance: 'none',
                                            },
                                            '& input::-webkit-inner-spin-button': {
                                                WebkitAppearance: 'none',
                                            },
                                            '& input[type="number"]': {
                                                MozAppearance: 'textfield',
                                            },
                                        },
                                    }}
                                />
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" mr={9}>
                                    Cash Payment
                                </Typography>
                                <TextField
                                    sx={{ width: '100px' }}
                                    variant="outlined"
                                    margin="normal"
                                    type={'number'}
                                    value={cashRcvd}
                                    onChange={handleCashRcvd}
                                    error={error.cashRcvd} // Show error state
                                    helperText={error.cashRcvd ? 'Field is required' : ''}
                                    InputProps={{
                                        sx: {
                                            '& input::-webkit-outer-spin-button': {
                                                WebkitAppearance: 'none',
                                            },
                                            '& input::-webkit-inner-spin-button': {
                                                WebkitAppearance: 'none',
                                            },
                                            '& input[type="number"]': {
                                                MozAppearance: 'textfield',
                                            },
                                        },
                                    }}
                                />
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" mr={9}>
                                    Card Payment
                                </Typography>
                                <TextField
                                    sx={{ width: '100px' }}
                                    value={cardAmount || 0}
                                    defaultValue="0"
                                    variant="outlined"
                                    margin="normal"
                                    type={'number'}
                                    disabled
                                />
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" mr={10}>
                                    UPI Payment
                                </Typography>
                                <TextField
                                    sx={{ width: '100px' }}
                                    value={upiAmount || 0}
                                    defaultValue="0"
                                    variant="outlined"
                                    margin="normal"
                                    type={'number'}
                                    disabled
                                />
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" mr={11}>
                                    Sales return
                                </Typography>
                                <TextField
                                    sx={{ width: '100px' }}
                                    // label="Sales return"
                                    defaultValue="0"
                                    variant="outlined"
                                    margin="normal"
                                    type={'number'}
                                    disabled
                                />
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" mr={11}>
                                    Cash return
                                </Typography>
                                <TextField
                                    sx={{ width: '100px' }}
                                    // label="Cash return"
                                    defaultValue="0"
                                    variant="outlined"
                                    margin="normal"
                                    type={'number'}
                                    disabled
                                />
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" mr={9}>
                                    Credit applied
                                </Typography>
                                <TextField
                                    sx={{ width: '100px' }}
                                    // label="Credit applied"
                                    defaultValue="0"
                                    variant="outlined"
                                    margin="normal"
                                    type={'number'}
                                    disabled
                                />
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" mr={13}>
                                    Pay later
                                </Typography>
                                <TextField
                                    sx={{ width: '100px' }}
                                    // label="Pay later"
                                    defaultValue="0"
                                    variant="outlined"
                                    margin="normal"
                                    type={'number'}
                                    disabled
                                />
                            </Box>

                            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                            <Typography variant="h6" align="right">
                                Total sales: {sum}
                            </Typography>
                        </Grid>

                        {/* Center Column */}

                        <Grid item xs={4}>
                            {[
                                { rupee: 1, multipleValue: 1 },
                                { rupee: 2, multipleValue: 10 },
                                { rupee: 5, multipleValue: 100 },
                                { rupee: 10, multipleValue: 1000 },
                                { rupee: 20, multipleValue: 10000 },
                                { rupee: 50, multipleValue: 1000 },
                                { rupee: 100, multipleValue: 10000 },
                                { rupee: 200, multipleValue: 1000 },
                                { rupee: 500, multipleValue: 10000 },
                            ].map((element, index) => (
                                <Box display="flex" alignItems="center" key={index} sx={{ mb: 2 }}>
                                    <Typography variant="h5" mr={2} sx={{ minWidth: '50px' }}>
                                        ₹{element.rupee}
                                    </Typography>
                                    <TextField
                                        sx={{ width: '70px', mr: 2 }}
                                        InputProps={{
                                            sx: {
                                                '& input::-webkit-outer-spin-button': {
                                                    WebkitAppearance: 'none',
                                                },
                                                '& input::-webkit-inner-spin-button': {
                                                    WebkitAppearance: 'none',
                                                },
                                                '& input[type="number"]': {
                                                    MozAppearance: 'textfield',
                                                },
                                            },
                                        }}
                                        label="Qty"
                                        variant="outlined"
                                        margin="normal"
                                        type="number"
                                        onChange={(e) => handleInputChange(e, element.rupee, index)}
                                    />
                                    <Typography variant="h5" ml={2} sx={{ minWidth: '100px' }}>
                                        {objectJSON[index]?.value ? (
                                            objectJSON[index].value * objectJSON[index].qty
                                        ) : (
                                            0
                                        )}
                                    </Typography>
                                </Box>
                            ))}

                            <Divider sx={{ my: 2 }} />

                            <Box display="flex" justifyContent="flex-end">
                                <Typography variant="h6">
                                    Total: {totalSum}
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Right Column */}
                        <Grid item xs={4}>

                            <TextField
                                fullWidth
                                InputProps={{
                                    sx: {
                                        '& input::-webkit-outer-spin-button': {
                                            WebkitAppearance: 'none',
                                        },
                                        '& input::-webkit-inner-spin-button': {
                                            WebkitAppearance: 'none',
                                        },
                                        '& input[type="number"]': {
                                            MozAppearance: 'textfield',
                                        },
                                    },
                                }}
                                label="Total cash left in drawer"
                                variant="outlined"
                                margin="normal"
                                type="number"
                                // helperText="Short: ₹0"
                                value={cashLeft}
                                onChange={handleCashInHandChange}
                                error={error.cashLeft} // Show error state
                                helperText={error.cashLeft ? 'Field is required' : ''}

                            />
                            <TextField
                                fullWidth
                                label="Closing note"
                                variant="outlined"
                                margin="normal"
                                maxRows={3}
                                value={closingNote}
                                onChange={handleclosingNote}
                                error={error.closingNote} // Show error state
                                helperText={error.closingNote ? 'Field is required' : ''}
                            />
                            <Button
                                variant="contained"
                                // color="primary"
                                fullWidth
                                onClick={handleSubmit}
                                disabled={loading}
                                style={{ marginTop: 16, color: "#27b6cc" }}
                            >
                                {loading ? (
                                    <CircularProgress size={24} style={{ color: 'white' }} />
                                ) : (
                                    <span style={{ color: 'white' }}>Close Register</span>
                                )}

                                {/* Close Register */}
                            </Button>
                        </Grid>
                    </Grid>
                    {/* </Box> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* for new customer */}
            <Dialog open={newOpen} onClose={handleNewClose}>
                <Typography marginBottom={'5px'} sx={{ fontSize: '24px', fontWeight: 500, alignSelf: 'center' }}>Add New Customer</Typography>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>

                            {!isOtpSent ?
                                <TextField
                                    fullWidth
                                    label="Mobile Number"
                                    variant="outlined"
                                    value={mobileNumber}
                                    // type={'number'}
                                    // onChange={(e) => setMobileNumber(e.target.value)}
                                    InputProps={{
                                        sx: {
                                            '& input::-webkit-outer-spin-button': {
                                                WebkitAppearance: 'none',
                                            },
                                            '& input::-webkit-inner-spin-button': {
                                                WebkitAppearance: 'none',
                                            },
                                            '& input[type="number"]': {
                                                MozAppearance: 'textfield',
                                            },
                                        },
                                    }}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 10 }}
                                    error={errorMobNo}
                                    helperText={errorMobNo ? 'Mobile number must be exactly 10 digits' : ''}
                                /> :
                                <TextField
                                    fullWidth
                                    label="OTP"
                                    type={'number'}
                                    value={otp}
                                    InputProps={{
                                        sx: {
                                            '& input::-webkit-outer-spin-button': {
                                                WebkitAppearance: 'none',
                                            },
                                            '& input::-webkit-inner-spin-button': {
                                                WebkitAppearance: 'none',
                                            },
                                            '& input[type="number"]': {
                                                MozAppearance: 'textfield',
                                            },
                                        },
                                    }}
                                    variant="outlined"
                                    onChange={(e) => {
                                        setOtp(e.target.value)
                                        setOtpVerify(!(e.target.value.length === 6))
                                    }}

                                />
                            }

                            {!isOtpSent &&
                                <Button variant="contained" color="primary" onClick={handleGetOtp}>
                                    Get OTP
                                </Button>
                            }

                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                disabled={otpVerify}
                                fullWidth
                                label="Name"
                                type={'text'}
                                variant="outlined"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                disabled={otpVerify}
                                type={'date'}
                                // label="Date of birth DD/MM/YYYY"
                                variant="outlined"
                                onChange={(e) => setDob(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                disabled={otpVerify}
                                label="Email"
                                type={'email'}
                                variant="outlined"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleNewClose} color="primary">
                        Cancel
                    </Button>

                    <Button
                        onClick={handleCustCreate}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={otpVerify}
                        style={{ width: '65px' }}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            {/* for the items that are on HOLD */}
            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Card sx={{ width: '350px', height: 'auto' }}>
                    {/* <CardContent> */}

                    <Typography ml={'10px'} variant="h5" component="div">
                        On hold
                    </Typography>
                    <Box
                        sx={{
                            height: 'calc(100% - 50px)', // Adjust height to fit inside the card
                            overflowY: 'auto',  // Enable vertical scrolling
                            padding: '10px'
                        }}
                    >

                        {
                            Get_Hold_Packets.Packets_Length > 0 ?
                                Get_Hold_Packets.ALL_HOLD_PACKETS.map((packet, index) => {
                                    return (
                                        <Grid
                                            ml={'10px'}
                                            container
                                            spacing={2}
                                            sx={{
                                                marginBottom: '5px',
                                                marginTop: '10px',
                                                width: '328px',
                                                height: '145px',
                                                backgroundColor: '#F3F4F6',
                                                flexDirection: 'column',
                                            }}
                                            onClick={() => { navigate("/app/product-management/product-pos?Hold_Id=" + packet.Hold_Id) }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between', // Ensures the IconButton is pushed to the right if needed
                                                }}
                                            >
                                                <Typography
                                                    ml={'10px'}
                                                    mt={'10px'}
                                                    sx={{ fontSize: '16px' }}
                                                >
                                                    Hold Id : {packet.Hold_Id}
                                                </Typography>
                                                <IconButton color="error" sx={{ marginRight: '10px' }}
                                                    onClick={() => {
                                                        const check = window.confirm("Are you want delete This Hold" + packet.Hold_Id)
                                                        if (check) {
                                                            const Hold_Packets = [...Get_Hold_Packets.ALL_HOLD_PACKETS]
                                                            let New_Hold_Packets = Hold_Packets.filter(value => value.Hold_Id !== packet.Hold_Id)
                                                            window.localStorage.setItem("Hold_Packets", JSON.stringify({ Hold_Packets: New_Hold_Packets }));
                                                            navigate("/app/product-management/product-pos")
                                                        }
                                                    }}

                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Box>

                                            <Typography
                                                ml={'10px'}
                                                mt={'10px'}
                                                sx={{ fontSize: '16px' }}
                                            >
                                                Contact Name : {packet.Contact_Name}
                                            </Typography>

                                            <Typography
                                                ml={'10px'}
                                                mt={'10px'}
                                                sx={{ fontSize: '16px' }}
                                            >
                                                Created on : {packet.Created_On}
                                            </Typography>

                                            <Typography
                                                ml={'10px'}
                                                mt={'10px'}
                                                sx={{ fontSize: '16px' }}
                                            >
                                                Invoice Amount : {packet.Flat_Amount}
                                            </Typography>
                                        </Grid>
                                    )
                                })
                                :
                                <Box sx={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} >
                                    <Typography sx={{ fontSize: "16px" }} >No Holds</Typography>
                                </Box>
                        }
                    </Box>

                    {/* Second Grid */}
                    {/* <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Text 5</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Text 6</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Text 7</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Text 8</Typography>
                                    </Grid>
                                </Grid> */}
                    {/* </CardContent> */}
                </Card>
            </Drawer>
        </Box >
    )
}

export default ProductPos;


const SelectedProductsTable = ({ row = {}, index = 0, setSelectedProducts = (() => { }), handleDelete = ((Product_Size_Id) => { }), handleDecrement, handleIncrement, blocks }) => {

    return (
        <TableRow >
            <TableCell component="th" scope="row">
                {row?.Product_Name + '-' + row?.Size}
            </TableCell>
            <TableCell align="center">
                <Box display="flex" alignItems="center" justifyContent="center" width='188px'>
                    <Button size='small' variant='contained' color='primary' sx={{ backgroundColor: '#27b6cc', width: '24px', height: '32px', padding: '6px', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', marginRight: '20px' }} onClick={() => handleIncrement(index)}>
                        <Add />
                    </Button>
                    <Typography variant="body1">{row?.quantity || 0}</Typography>
                    <Button disabled={row?.quantity === 1}
                        size='small' variant='contained' color='primary' sx={{ backgroundColor: '#27b6cc', width: '24px', height: '32px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', marginLeft: '20px' }} onClick={() => handleDecrement(index)}>
                        <Remove />
                    </Button>
                </Box>
            </TableCell>
            <TableCell align="center">{row?.Partner_Price || 0}</TableCell>
            {/* <TableCell align="center">{row.discount}</TableCell> */}

            <TableCell align="center">
                <TextField
                    disabled={((row.Discount_Type == 'P') || (blocks.Flat_Discount)) ? true : false}
                    value={row.Discount}
                    onChange={(e) => {
                        const discountAmount = e.currentTarget.value
                        setSelectedProducts(state => {
                            let data = [...state]
                            let currentProduct = data[index]
                            currentProduct["Discount"] = Number(discountAmount)
                            currentProduct["Partner_Selling_Price"] = (currentProduct.Partner_Price) - Number(discountAmount)
                            data[index] = currentProduct
                            return data
                        })
                    }}
                    size="small"
                    sx={{ width: '60px' }}
                />
            </TableCell>

            <TableCell align="center">{(row?.Partner_Selling_Price) || 0}</TableCell>
            <TableCell align="center">
                <IconButton onClick={() => handleDelete(row.Product_Size_Id)} color="error">
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}


{/* <DiscountTableRow disabled={row.Discount_Type == 'P' ? true : false} row={row} initialDiscount={row.Discount}
                handleDiscountToNetPrice={(discountAmount = 0) => {
                    setSelectedProducts(state => {
                        let data = [...state]
                        let currentProduct = data[index]
                        currentProduct["Partner_Selling_Price"] = (currentProduct.Partner_Selling_Price) - Number(discountAmount)
                        data[index] = currentProduct
                        return data
                    })
                }
                }
            /> */}

// const DiscountTableRow = ({ initialDiscount, disabled = false, handleDiscountToNetPrice = ((discountAmount) => { }), row }) => {
//     const [discount, setDiscount] = useState(0);

//     const handleDiscountChange = (event) => {
//         const prodDisVldtn = event.target.value.replace(/\D/g, '')
//         if (prodDisVldtn <= (Number(row.Partner_Price) * row.quantity) && prodDisVldtn >= 0) {
//             setDiscount(prodDisVldtn);
//             handleDiscountToNetPrice(prodDisVldtn)
//         }
//         else {
//             setDiscount(Number(row.Partner_Price) * row.quantity);
//             handleDiscountToNetPrice(Number(row.Partner_Price) * row.quantity)
//         }
//     };

//     useEffect(() => {
//         setDiscount(initialDiscount)
//     }, [initialDiscount])

//     return (
//         <TableCell align="center">
//             <TextField
//                 disabled={disabled}
//                 value={discount}
//                 onChange={handleDiscountChange}
//                 size="small"
//                 sx={{ width: '60px' }}
//             />
//         </TableCell>
//     );
// };

