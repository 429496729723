import React, { useEffect, useRef, useState } from 'react';
import {
  Container, Grid, Typography, TextField, FormControl, RadioGroup, Radio, FormControlLabel,
  Button, Box, Divider, CardMedia, Card, Autocomplete, Checkbox, Dialog, DialogContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination
} from '@mui/material';
import { CardActions, CardContent, FormLabel, styled } from '@material-ui/core';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCorpProfilesAction } from 'src/store/actions/corporateticketAction';
import { createCorpCouponAction, getAllCorpSubscriptionAction, getcorpCouponImages, uploadCorpCouponImage, getAllCorpCompanyNamesAction, getAllCorpEmployeesAction } from 'src/store/actions/corporatecouponAction';
import { useNavigate } from 'react-router-dom';
import { ConnectedFocusError } from 'focus-formik-error';
import { getAllFilterCategories } from 'src/store/actions/corporateproductAction';
import SearchIcon from "@material-ui/icons/Search";
import { paginationCorpCouponEmployeesGet } from 'src/store/actions/node-actions/nodepagination';
import isEmpty from 'src/store/validations/is-empty';

const couponTypeOption = [
  {
    value: 'false',
    label: 'Meepaisa'
  },
  {
    value: 'true',
    label: 'Elev8'
  }
];

const discountlabelOption = [
  {
    value: 'Upto',
    label: 'Upto'
  },
  {
    value: 'Flat',
    label: 'Flat'
  }
];

const AddCorporateCoupon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const users = useSelector(state => state.corptickets.corpusers.users);
  const couponImage = useSelector((state) => state.corpcoupons.corpcouponImages?.couponImage?.results || []);
  const initialImagesCount = 7;

  const CustomUploadLabel = styled('label')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '100%',
    height: "120px",
    border: '1px dashed gray',
    borderRadius: 4,
    backgroundColor: '#f5f5f5',
    '& img': {
      width: '50%',
    }
  });

  const imagesToShow = couponImage && couponImage?.slice(0, initialImagesCount);
  const [uploadedImage, setUploadedImage] = useState({ preview: '', raw: '' });
  const [redemptionLimit, setRedemptionLimit] = useState('User Specific');
  const [isExpirableCoupon, setIsExpirableCoupon] = useState(true);
  const [isManuallySelectEmployees, setIsManuallySelectEmployees] = useState(true);
  const [isSelectDepartments, setIsSelectDepartments] = useState(false);
  const [isAllempoyees, setIsallempoyees] = useState(false);
  const [isUserspecific, setUserspecific] = useState(true);
  const [issingleuse, setIssingleuse] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedOption, setSelectedOption] = useState('individual');
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [discount, setDiscount] = useState('');
  const [message, setMessage] = useState('');
  const [imgId, setImageId] = useState('');
  const [isElevateCoupon, setIsElevateCoupon] = useState(false);
  const [couponDiscount, setcouponDiscount] = useState('Upto');
  const [filtercategories, setFiltercategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [couponValidity, setCouponValidity] = useState(false);
  const [coupontandc, setCoupontandc] = useState('');
  const corpsubscriptions = useSelector(state => state.corpcoupons.corpsubscriptions.subscriptions);
  const corpcompanynames = useSelector(state => state.corpcoupons.corpcompanynames.companynames || []);
  const corpcompanyemployees = useSelector(state => state.corpcoupons.corpcompanyemployees.companyemployees || []);

  useEffect(() => {
    if (couponImage.length > 0) {
      setSelectedImage(couponImage[0]?.Image_Url || null);
      setImageId(couponImage[0]?.id)
    }
  }, [couponImage]);

  useEffect(() => {
    if (formikRef.current) {
      if (redemptionLimit === "Single Use") {
        formikRef.current.setFieldValue('Times_Of_Use', 1);
      }
    }
  }, [redemptionLimit, formikRef]);

  useEffect(() => {
    dispatch(getAllCorpProfilesAction());
    dispatch(getAllCorpSubscriptionAction());
    dispatch(getcorpCouponImages());
    dispatch(getAllFilterCategories("", callBackCategoriesdata))
  }, [dispatch]);

  const handleRadioChange = (event, setFieldValue) => {
    const isExpirable = event.target.value === 'true';
    setIsExpirableCoupon(isExpirable);
    setFieldValue('Is_Expirable_Coupon', isExpirable);
    if (!isExpirable) {
      setFieldValue('Valid_To_Date', '');
    }
  };

  const handleRadioChangeRedumption = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "User Specific") {
      setRedemptionLimit("User Specific");
      setUserspecific(true)
      setIssingleuse(false);
    } else if (selectedValue === "Single Use") {
      setRedemptionLimit("Single Use");
      setUserspecific(false)
      setIssingleuse(true);
    }
  };

  const callBackUploadImgae = data => {
    if (data) {
      setSelectedImage(data.path);
      setImageId(data.id)
    }
  };
  const handleFileUpload = e => {
    e.preventDefault();
    const file = e.target.files[0]
    if (file) {
      setUploadedImage({
        preview: URL.createObjectURL(file),
        raw: file
      });
      const data = new FormData();
      data.append('file', file);
      dispatch(uploadCorpCouponImage(data, callBackUploadImgae))
    }
  };

  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    setSelectedEmployees([]);
    setSelectedDepartments([]);

    switch (option) {
      case 'individual':
        setIsManuallySelectEmployees(true);
        setIsSelectDepartments(false);
        setIsallempoyees(false)
        break;
      case 'subscription':
        setIsallempoyees(false)
        setIsManuallySelectEmployees(false);
        setIsSelectDepartments(true);
        break;
      case 'all':
        setIsallempoyees(true)
        setIsManuallySelectEmployees(false);
        setIsSelectDepartments(false);
        break;
      default:
        setIsallempoyees(false)
        setIsManuallySelectEmployees(false);
        setIsSelectDepartments(false);
    }
  };

  let giftedEmployees = [];
  let giftedDepartments = [];

  if (selectedOption === 'all') {
    giftedEmployees = users?.map((employee) => employee.Company_Name);
  } else if (selectedOption === 'subscription') {
    giftedDepartments = selectedDepartments
  } else if (selectedOption === 'individual') {
    giftedEmployees = selectedEmployees;
  }

  Yup.addMethod(Yup.string, 'maxWords', function (max, message) {
    return this.test({
      name: 'maxWords',
      exclusive: true,
      message: message,
      test: (value) => {
        if (!value) return true;
        const wordCount = value.split(/\s+/).filter((word) => word.length > 0).length;
        return wordCount <= max;
      },
    });
  });

  const handleChangeIsElevateCoupon = (value) => {
    setIsElevateCoupon(value);
  };

  const handleChangeCouponDiscount = (value) => {
    setcouponDiscount(value);
  };

  const callBackCategoriesdata = async (status, data,) => {
    if (status) {
      setFiltercategories(data);
    }
  }

  const handleCouponValidity = (value) => {
    setCouponValidity(value)
  };

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedCompanyEmployees, setSelectedCompanyEmployees] = useState([]);
  const [tempSelectedEmployees, setTempSelectedEmployees] = useState([]);
  const [company, setCompany] = useState('');
  const [employee, setEmployee] = useState('');
  const selecteduseremails = selectedCompanyEmployees.map((emp) => emp.email);
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 5, sortBy: "createdAt:desc" })

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [corpcouponemployees, setCorpCouponEmployees] = useState([]);
  const [filters, setFilters] = React.useState({
    "Company_Name": "",
    "Search": ""
  });

  const handleAddRecipientClick = () => setOpenPopup(true);

  const handlePopupClose = () => setOpenPopup(false);

  const handleCheckboxChange = (employee) => {
    setTempSelectedEmployees((prev) =>
      prev.some((e) => e.email === employee.email)
        ? prev.filter((e) => e.email !== employee.email)
        : [...prev, employee]
    );
  };

  const handleAddButtonClick = () => {
    setSelectedCompanyEmployees(tempSelectedEmployees);
    setOpenPopup(false);
  };

  const fetchTableData = () => {
    dispatch(paginationCorpCouponEmployeesGet(filters, pagination, callBackPagination));
  };

  useEffect(() => {
    const companyformdata = { Company_Name: '' };
    dispatch(getAllCorpCompanyNamesAction(companyformdata));
  }, [dispatch]);

  useEffect(() => {
    if (company) {
      const employeeformdata = { Company_Name: company, Search: '' };
      dispatch(getAllCorpEmployeesAction(employeeformdata));
    }
  }, [company, dispatch]);

  useEffect(() => {
    if (filters.Company_Name || filters.Search) {
      fetchTableData();
    }
  }, [filters]);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setCompany(selectedCompany);
    setEmployee('');

    setFilters({
      Company_Name: selectedCompany,
      Search: '',
    });

    setPagination({ page: 1, limit: 5, sortBy: "createdAt:desc" });

    const employeeformdata = { Company_Name: selectedCompany, Search: '' };
    dispatch(getAllCorpEmployeesAction(employeeformdata));
  };

  const handleEmployeeChange = (event) => {
    const selectedEmployee = event.target.value;
    setEmployee(selectedEmployee);

    setFilters({
      Company_Name: company,
      Search: selectedEmployee,
    });

    setPagination({ page: 1, limit: 5, sortBy: "createdAt:desc" });
  };

  const handleLimitChange = event => {
    setPagination({ ...pagination, limit: +event.target.value, page: 1, sortBy: "createdAt:desc" });
  };

  const handlePageChange = (event, newPage) => {
    setPagination({ ...pagination, limit: pagination.limit, page: newPage + 1, sortBy: "createdAt:desc" });
  };

  const callBackPagination = async (status, data, pagination) => {
    if (status) {
      setCorpCouponEmployees(data);
    }
    setPageOld(true);
    if (pagination) {
      pagination.sortBy = 'createdAt:desc'
      setPagination(pagination);
    } else {
      setPagination({ page: 1, limit: 5, sortBy: "createdAt:desc" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      corpcouponhistoryCall();
    }
  }, [pagination])

  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
      setTestFilters(filters);
    }
  }, [filters]);

  const corpcouponhistoryCall = () => {
    dispatch(paginationCorpCouponEmployeesGet(filters, pagination, callBackPagination))
  };

  useEffect(() => {
    dispatch(paginationCorpCouponEmployeesGet(filters, pagination, callBackPagination));
  }, []);

  return (
    <Container sx={{ backgroundColor: "#ffffff", marginTop: 3 }}>
      <Grid container spacing={3} sx={{ paddingY: 2 }}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            onChange={(e) => handleChangeIsElevateCoupon(e.target.value === "true")}
            label="Coupon type"
            name="Is_Elevate_Coupon"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ native: true }}
            value={isElevateCoupon}
            variant="outlined"
          >
            {couponTypeOption.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>1. Select your coupon style</Typography>
          {selectedImage && (
            <Grid
              container
              sx={{
                display: 'flex',
                minWidth: '360px',
                height: '308px',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                flexWrap: 'wrap',
                gap: '24px',
                flex: '1 0 0',
              }}
            >
              {selectedImage && (
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    height: '171px',
                    padding: '10px',
                    maxWidth: '368px',
                    minWidth: '328px',
                    gap: '10px',
                    flexDirection: 'column',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="194"
                    image={selectedImage}
                    alt="Selected Image"
                  />
                  <Grid
                    container
                    item
                    sx={{ display: 'flex', gap: '12px', flexDirection: 'row', justifyContent: 'space-between' }}
                  >
                    <Grid item>
                      {isElevateCoupon ?
                        <img height="25" src="/static/Elev8_logo_ind.png" alt="" />
                        :
                        <img height="25" src="/static/Meepaisa-black-text-logo.png" alt="" />
                      }
                    </Grid>
                    <Grid item>
                      <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0.15px' }}>{discount ? discount : 0}% off on selected products</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ margin: 0 }} />
                  <Typography variant="caption" color="text.secondary" sx={{ color: '#00008', fontSize: '12px', fontWeight: 400, lineHeight: '19.92px', letterSpacing: '0.4px' }}>
                    {message ? message : "Thank you for your hard work and dedication! Please enjoy this special coupon as a token of our appreciation."}
                  </Typography>
                </Grid>
              )}
              <Grid container item sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-end', gap: '20px', flex: '1 0 0' }}>

                <Grid container item spacing={2}>
                  {imagesToShow.map((img, i) => (
                    <Grid item xs={3} key={i}>
                      <Card
                        sx={{
                          display: 'flex',
                          height: '120px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSelectedImage(img.Image_Url);
                          setImageId(img.id)
                          setUploadedImage({ preview: '', raw: '' });
                        }}
                      >
                        <CardMedia
                          component="img"
                          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                          image={img.Image_Url}
                          alt="Paella dish"
                        />
                      </Card>
                    </Grid>
                  ))}
                  <Grid item xs={3}>
                    <CustomUploadLabel htmlFor="file-upload">
                      {uploadedImage.preview ? (
                        <img src={uploadedImage.preview} alt="Uploaded" style={{ width: '100%', height: '120px' }} />
                      ) : (
                        <UploadFileIcon />
                      )}
                    </CustomUploadLabel>
                    <input
                      style={{ display: 'none', height: '120px ' }}
                      id="file-upload"
                      name="uploadedImage"
                      accept="image/*"
                      type="file"
                      onChange={handleFileUpload}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" gutterBottom>2. Enter coupon details</Typography>
          <Formik
            innerRef={formikRef}
            initialValues={{
              Coupon_Title: "",
              Coupon_Prefix: "",
              Discount_Percentage: '',
              Description: "",
              Times_Of_Use: '',
              Is_User_Specific: true,
              Is_Single_use: false,
              Is_Expirable_Coupon: isExpirableCoupon,
              Valid_To_Date: '',
              Is_Manually_Select_Employees: isManuallySelectEmployees,
              Is_Select_Departments: isSelectDepartments,
              Is_All_Employees: isAllempoyees,
              Image_Path: selectedImage,
              Created_By: loginEmail,
              employees: selecteduseremails,
              // Department_Id: giftedDepartments ? giftedDepartments : [],
              Department_Id: giftedEmployees ? giftedEmployees : [],
              Subscription_Type: giftedDepartments ? giftedDepartments : [],
              corp_Coupon_Static_Image_Id: imgId,
              Is_Elevate_Coupon: isElevateCoupon,
              Category_Id: category,
              Coupon_Max_Discount_Amount: '',
              Min_Order_Value: '',
              Discount_Type: couponDiscount,
              Is_Coupon_Validity: couponValidity,
              Start_Date: "",
              End_Date: "",
              Coupon_TandC: coupontandc,
              Coupon_Type: isElevateCoupon ? "Elev8" : "Meepaisa"
            }}
            validationSchema={Yup.object().shape({
              Coupon_Title: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Coupon title should not start with Spaces and Special Characters.").required('Coupon Name is required.'),
              Coupon_Prefix: Yup.string().required('Coupon Prefix is required.'),
              Discount_Percentage: Yup.number().min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be less than 100.").required('Discount Percentage is required.'),
              Times_Of_Use: Yup.string()
                .when('Is_User_Specific', {
                  is: true,
                  then: Yup.string().required('Times Of Use is required'),
                  otherwise: Yup.string(),
                }),
              Description: Yup.string()
                .required('Description is required.')
                .maxWords(15, 'Description must be 15 words or less.'),
              Is_Expirable_Coupon: Yup.boolean().when('isElevateCoupon', {
                is: true,
                then: Yup.boolean().required('Required'),
                otherwise: Yup.boolean(),
              }),
              Valid_To_Date: Yup.date().when(['isElevateCoupon', 'Is_Expirable_Coupon'], {
                is: (isElevateCoupon, Is_Expirable_Coupon) => isElevateCoupon && Is_Expirable_Coupon,
                then: Yup.date().required('Expiry Date is required').nullable(),
                otherwise: Yup.date().nullable(),
              }),
              Is_Manually_Select_Employees: Yup.boolean().when('isElevateCoupon', {
                is: true,
                then: Yup.boolean().required('Required'),
                otherwise: Yup.boolean(),
              }),
              Is_Select_Departments: Yup.boolean().when('isElevateCoupon', {
                is: true,
                then: Yup.boolean().required('Required'),
                otherwise: Yup.boolean(),
              }),
              Department_Id: Yup.array().when(['isElevateCoupon', 'Is_Manually_Select_Employees', 'Is_Select_Departments', 'Is_All_Employees'], {
                is: (isElevateCoupon, Is_Manually_Select_Employees, Is_Select_Departments, Is_All_Employees) =>
                  isElevateCoupon && Is_Manually_Select_Employees && !Is_Select_Departments && !Is_All_Employees,
                then: Yup.array().min(1, 'Please select at least one company.'),
                otherwise: Yup.array(),
              }),
              Subscription_Type: Yup.array().when(['isElevateCoupon', 'Is_Select_Departments', 'Is_Manually_Select_Employees', 'Is_All_Employees'], {
                is: (isElevateCoupon, Is_Select_Departments, Is_Manually_Select_Employees, Is_All_Employees) =>
                  isElevateCoupon && Is_Select_Departments && !Is_Manually_Select_Employees && !Is_All_Employees,
                then: Yup.array().min(1, 'Please select at least one subscription.'),
                otherwise: Yup.array(),
              }),
            })}
            onSubmit={(values, { setSubmitting }) => {
              let submitTest = true;
              values.employees = selecteduseremails;
              // values.Department_Id = giftedDepartments ? giftedDepartments : [];
              values.Department_Id = giftedEmployees ? giftedEmployees : [];
              values.Subscription_Type = giftedDepartments ? giftedDepartments : [];
              values.Image_Path = selectedImage;
              values.corp_Coupon_Static_Image_Id = imgId;
              values.Is_Expirable_Coupon = isExpirableCoupon;
              values.Is_Manually_Select_Employees = isManuallySelectEmployees;
              values.Is_Select_Departments = isSelectDepartments;
              values.Is_All_Employees = isAllempoyees;
              values.Is_User_Specific = isUserspecific;
              values.Is_Single_use = issingleuse;
              values.Is_Elevate_Coupon = isElevateCoupon;
              values.Is_Coupon_Validity = couponValidity;
              values.Category_Id = category;
              values.employees = selecteduseremails;
              values.Discount_Type = couponDiscount;
              values.Coupon_Type = isElevateCoupon ? "Elev8" : "Meepaisa";
              setSubmitting(false);
              setIsSubmitted(true);
              if (submitTest) {
                if (values) {
                  let formValues = JSON.parse(JSON.stringify(values));
                  dispatch(createCorpCouponAction(formValues, navigate));
                }
              }
            }}
          >
            {({
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              touched,
            }) => (
              <form onSubmit={handleSubmit} >
                <ConnectedFocusError />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label='Coupon Title'
                        name='Coupon_Title'
                        value={values.Coupon_Title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(touched.Coupon_Title && errors.Coupon_Title)}
                        helperText={touched.Coupon_Title && errors.Coupon_Title}
                        InputLabelProps={{
                          sx: {
                            '&.MuiInputLabel-root': {
                              '&::after': {
                                content: "'*'",
                                color: 'red',
                                marginLeft: '2px',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label='Coupon Prefix'
                        value={values.Coupon_Prefix}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          const noSpacesValue = value.replace(/\s+/g, '');
                          setFieldValue(name, noSpacesValue);
                        }}
                        name='Coupon_Prefix'
                        fullWidth
                        error={Boolean(touched.Coupon_Prefix && errors.Coupon_Prefix)}
                        helperText={touched.Coupon_Prefix && errors.Coupon_Prefix}
                        InputLabelProps={{
                          sx: {
                            '&.MuiInputLabel-root': {
                              '&::after': {
                                content: "'*'",
                                color: 'red',
                                marginLeft: '2px',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label='Discount Percentage'
                        name='Discount_Percentage'
                        type='number'
                        fullWidth
                        inputProps={{
                          min: 0,
                          max: 99,
                          step: 1,
                        }}
                        onChange={(e) => {
                          let inputValue = e.target.value.trim();
                          inputValue = inputValue.replace(/\D/g, '');
                          inputValue = inputValue.slice(0, 2);

                          if (inputValue === '' || (parseInt(inputValue, 10) >= 0 && parseInt(inputValue, 10) <= 99)) {
                            setDiscount(inputValue);
                            handleChange({
                              target: {
                                id: 'Discount_Percentage',
                                value: inputValue,
                              }
                            });
                          }
                        }}
                        error={Boolean(touched.Discount_Percentage && errors.Discount_Percentage)}
                        helperText={touched.Discount_Percentage && errors.Discount_Percentage}
                        InputLabelProps={{
                          sx: {
                            '&.MuiInputLabel-root': {
                              '&::after': {
                                content: "'*'",
                                color: 'red',
                                marginLeft: '2px',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label='Min order value'
                        name='Min_Order_Value'
                        value={values.Min_Order_Value}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        type='number'
                        inputProps={{
                          min: 0,
                          step: 1,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        onBlur={handleBlur}
                        onChange={(e) => handleChangeCouponDiscount(e.target.value)}
                        label="Discount type"
                        name="Discount_Type"
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                        value={couponDiscount}
                        variant="outlined"
                      >
                        {discountlabelOption.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label='Max discount amount'
                        name='Coupon_Max_Discount_Amount'
                        value={values.Coupon_Max_Discount_Amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        type='number'
                        inputProps={{
                          min: 0,
                          step: 1,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label='Your message'
                        name='Description'
                        value={values.Description}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          const pastedText = e.nativeEvent.inputType === 'insertFromPaste';
                          const trimmedValue = pastedText || !value.startsWith(' ') ? value : value.trim();
                          const normalizedValue = trimmedValue.replace(/\s+/g, ' ');

                          const words = normalizedValue.split(' ');

                          const maxWords = 15;

                          if (trimmedValue.length === 0) {
                            setFieldValue(name, '');
                          } else if (words.length <= maxWords) {
                            setFieldValue(name, normalizedValue.slice(0, 150));
                            setMessage(normalizedValue.slice(0, 150))
                          } else {
                            const truncatedValue = words.slice(0, maxWords).join(' ');
                            setFieldValue(name, truncatedValue);
                            setMessage(truncatedValue)
                          }
                        }}
                        error={Boolean(touched.Description && errors.Description)}
                        helperText={touched.Description && errors.Description}
                        multiline={true}
                        InputLabelProps={{
                          sx: {
                            '&.MuiInputLabel-root': {
                              '&::after': {
                                content: "'*'",
                                color: 'red',
                                marginLeft: '2px',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    {isElevateCoupon &&
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{ paddingY: 2, fontWeight: 'bold' }} gutterBottom={false}>Discount Category</Typography>
                          {/* <Autocomplete
                            multiple
                            limitTags={1}
                            id="multiple-limit-tags"
                            options={filtercategories}
                            disableCloseOnSelect
                            groupBy={(option) => option.firstLetter}
                            variant="outlined"
                            value={filtercategories.filter((option) => values.Category_Id.includes(option._id))}
                            getOptionLabel={(option) => option.Category_Name}
                            onChange={(e, value) => {
                              const selectedIds = value.map((item) => item._id);
                              setFieldValue("Category_Id", selectedIds);
                              setCategory(selectedIds);
                            }}
                            renderInput={(params) => <TextField {...params}
                              onBlur={handleBlur}
                              label="Category"
                              name="Category_Id"
                              variant="outlined"
                              onChange={(e) => {
                                dispatch(getAllFilterCategories(params.inputProps.value, callBackCategoriesdata));
                              }}
                              InputProps={{
                                ...params.InputProps,
                                autoComplete: 'off',
                                startAdornment: (
                                  <React.Fragment>
                                    <SearchIcon color="inherit" size={20}></SearchIcon>
                                    {params.InputProps.startAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />}
                          /> */}
                          <Autocomplete
                            multiple
                            limitTags={1}
                            id="multiple-limit-tags"
                            options={filtercategories}
                            disableCloseOnSelect
                            groupBy={(option) => option.firstLetter}
                            variant="outlined"
                            value={selectedCategories}
                            getOptionLabel={(option) => option.Category_Name}
                            onChange={(e, value) => {
                              const updatedCategories = value.filter((option, index, self) =>
                                index === self.findIndex((o) => o._id === option._id)
                              );
                              setSelectedCategories(updatedCategories);

                              const selectedIds = updatedCategories.map((item) => item._id);
                              setFieldValue("Category_Id", selectedIds);
                              setCategory(selectedIds);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onBlur={handleBlur}
                                label="Category"
                                name="Category_Id"
                                variant="outlined"
                                onChange={(e) => {
                                  dispatch(getAllFilterCategories(params.inputProps.value, callBackCategoriesdata));
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  autoComplete: 'off',
                                  startAdornment: (
                                    <React.Fragment>
                                      <SearchIcon color="inherit" size={20} />
                                      {params.InputProps.startAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                          {isElevateCoupon && values.Category_Id.length === 0 && (
                            <span style={{ color: "red", paddingY: 2 }}>
                              <Typography>Category is required</Typography>
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={6}></Grid>
                      </>
                    }
                    <Grid item xs={6} sm={4}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Redemption Limit</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          aria-label="redemptionLimit"
                          value={redemptionLimit}
                          onChange={handleRadioChangeRedumption}
                        >
                          <FormControlLabel value="User Specific" control={<Radio />} label="User Specific" />
                          <FormControlLabel value="Single Use" control={<Radio />} label="Single Use" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {isUserspecific && (
                      <Grid item xs={6} sm={4}>
                        <TextField
                          fullWidth
                          label='Times Of Use'
                          value={values.Times_Of_Use}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            const { value, name } = event.target;
                            if (/^(?!0+$)\d*$/.test(value)) {
                              handleChange(event);
                            }
                          }}
                          error={Boolean(touched.Times_Of_Use && errors.Times_Of_Use)}
                          helperText={touched.Times_Of_Use && errors.Times_Of_Use}
                          name='Times_Of_Use'
                          type='text'
                        />
                      </Grid>
                    )}
                    <Grid item xs={6} sm={4}></Grid>
                    {isElevateCoupon &&
                      <Grid item md={12} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={couponValidity}
                              onChange={(e) => handleCouponValidity(!couponValidity)}
                              color="primary"
                            />
                          }
                          label="Coupon validity"
                          labelPlacement="start"
                          sx={{
                            marginLeft: -0.3,
                            fontWeight: 'bold',
                          }}
                        />
                      </Grid>
                    }
                    {isElevateCoupon && couponValidity &&
                      <>
                        <Grid item xs={6} sm={4}>
                          <TextField
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Start date"
                            type="date"
                            name="Start_Date"
                            value={values.Start_Date}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              min: new Date().toISOString().split('T')[0]
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <TextField
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="End date"
                            type="date"
                            name="End_Date"
                            value={values.End_Date}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              min: values.Start_Date
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}></Grid>
                      </>
                    }
                    {isElevateCoupon &&
                      <>
                        <Grid item xs={8}>
                          <Typography sx={{ paddingY: 2, fontWeight: 'bold' }} gutterBottom={false}>
                            Recipient(s)
                          </Typography>
                          <Autocomplete
                            multiple
                            options={[]}
                            getOptionLabel={(option) => option.email}
                            value={selectedCompanyEmployees}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Recipients"
                                placeholder="Select Recipients"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: null,
                                }}
                              />
                            )}
                            onChange={(event, value) => setSelectedCompanyEmployees(value)}
                            open={false}
                            disableClearable
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="medium"
                            style={{ height: 40, marginTop: 65 }}
                            onClick={handleAddRecipientClick}
                            endIcon={<img src={"/static/Masked-Icon.png"} alt="Add Icon" style={{ width: 20, height: 20 }} />}
                          >
                            Add Recipient
                          </Button>
                        </Grid>
                        <Dialog
                          open={openPopup}
                          onClose={handlePopupClose}
                          PaperProps={{
                            style: { width: '600px', maxHeight: '70vh' },
                          }}
                        >
                          <DialogContent
                            style={{ paddingY: '15px', paddingX: '10px', overflowY: 'auto' }}
                          >
                            <Grid container spacing={2} marginBottom={2}>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Company"
                                  name="Company"
                                  select
                                  InputLabelProps={{ shrink: true }}
                                  SelectProps={{ native: true }}
                                  value={company}
                                  onChange={handleCompanyChange}
                                  variant="outlined"
                                >
                                  <option value="">All</option>
                                  {corpcompanynames?.map((option) => (
                                    <option key={option.id} value={option.Company_Name}>
                                      {option.Company_Name}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Employee"
                                  name="Employee"
                                  select
                                  InputLabelProps={{ shrink: true }}
                                  SelectProps={{ native: true }}
                                  value={employee}
                                  onChange={handleEmployeeChange}
                                  variant="outlined"
                                >
                                  <option value="">All</option>
                                  {corpcompanyemployees && corpcompanyemployees?.length > 0 && corpcompanyemployees?.map((option) => (
                                    <option key={option.id} value={option.Full_Name}>
                                      {option.Full_Name}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>

                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        indeterminate={
                                          tempSelectedEmployees.length > 0 &&
                                          tempSelectedEmployees.length < corpcouponemployees?.length
                                        }
                                        checked={
                                          tempSelectedEmployees.length > 0 &&
                                          tempSelectedEmployees.length === corpcouponemployees?.length
                                        }
                                        onChange={(event) => {
                                          if (event.target.checked) {
                                            setTempSelectedEmployees([...corpcouponemployees]);
                                          } else {
                                            setTempSelectedEmployees([]);
                                          }
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>User</TableCell>
                                    <TableCell>Email</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {!isEmpty(corpcouponemployees) &&
                                    (corpcouponemployees).map((employee, index) => (
                                      <TableRow key={index}>
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            checked={tempSelectedEmployees.some(
                                              (e) => e.email === employee.email
                                            )}
                                            onChange={() => handleCheckboxChange(employee)}
                                          />
                                        </TableCell>
                                        <TableCell>{employee.Full_Name}</TableCell>
                                        <TableCell>{employee.email}</TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            {isEmpty(corpcouponemployees) &&
                              <>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                              </>
                            }

                            <div style={{ marginTop: '5px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                              {(pagination?.totalPages && pagination?.totalPages >= 1) ?
                                <TablePagination
                                  component="div"
                                  count={pagination?.totalResults}
                                  onPageChange={handlePageChange}
                                  onRowsPerPageChange={handleLimitChange}
                                  page={pagination?.page - 1}
                                  rowsPerPage={pagination?.limit}
                                  rowsPerPageOptions={[5, 10]}
                                  style={{ alignSelf: 'stretch' }}
                                />
                                :
                                <TablePagination
                                  component="div"
                                  count={0}
                                  onPageChange={handlePageChange}
                                  onRowsPerPageChange={handleLimitChange}
                                  page={0}
                                  rowsPerPage={10}
                                  rowsPerPageOptions={[5, 10]}
                                  style={{ alignSelf: 'stretch' }}
                                />
                              }
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  marginTop: 5,
                                  width: '100px',
                                  alignSelf: 'flex-end',
                                }}
                                onClick={handleAddButtonClick}
                              >
                                Add +
                              </Button>
                            </div>
                          </DialogContent>
                        </Dialog>

                        <Grid item xs={8}>
                          <Typography sx={{ paddingY: 2, fontWeight: 'bold' }} gutterBottom={false}>Coupon T & C</Typography>
                          <TextField
                            fullWidth
                            label='Terms of use'
                            name='Coupon_TandC'
                            value={values.Coupon_TandC}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              const pastedText = e.nativeEvent.inputType === 'insertFromPaste';
                              const trimmedValue = pastedText || !value.startsWith(' ') ? value : value.trim();
                              const normalizedValue = trimmedValue.replace(/\s+/g, ' ');

                              const words = normalizedValue.split(' ');

                              const maxWords = 15;

                              if (trimmedValue.length === 0) {
                                setFieldValue(name, '');
                              } else if (words.length <= maxWords) {
                                setFieldValue(name, normalizedValue.slice(0, 150));
                                setCoupontandc(normalizedValue.slice(0, 150))
                              } else {
                                const truncatedValue = words.slice(0, maxWords).join(' ');
                                setFieldValue(name, truncatedValue);
                                setCoupontandc(truncatedValue)
                              }
                            }}
                            multiline={true}
                          />
                        </Grid>
                      </>
                    }
                    {!isElevateCoupon &&
                      <>
                        <Grid item xs={6} sm={4}>
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Coupon Expiry</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              value={String(isExpirableCoupon)}
                              onChange={(event) => handleRadioChange(event, setFieldValue)}>
                              <FormControlLabel value="true" control={<Radio />} label="Yes" />
                              <FormControlLabel value="false" control={<Radio />} label="No" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {isExpirableCoupon && (
                          <Grid item xs={6} sm={4}>
                            <TextField
                              fullWidth
                              label="Expiry Date"
                              value={values.Valid_To_Date}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name='Valid_To_Date'
                              type="date"
                              variant="outlined"
                              error={Boolean(touched.Valid_To_Date && errors.Valid_To_Date)}
                              helperText={touched.Valid_To_Date && errors.Valid_To_Date}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                min: new Date().toISOString().split('T')[0]
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={6} sm={4}></Grid>

                        <Grid item xs={6} sm={4}>
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Recipient</FormLabel>
                            <RadioGroup value={selectedOption}
                              onChange={(e) => {
                                handleOptionChange(e);
                                setFieldValue('Is_Manually_Select_Employees', e.target.value === 'individual');
                                setFieldValue('Is_Select_Departments', e.target.value === 'subscription');
                                setFieldValue('Is_All_Employees', e.target.value === 'all');
                              }}
                            >
                              <FormControlLabel value="individual" control={<Radio />} label="Individual Companies" />
                              <FormControlLabel value="subscription" control={<Radio />} label="Subscription" />
                              <FormControlLabel value="all" control={<Radio />} label="All Companies" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {isManuallySelectEmployees && (
                          <Grid item xs={6} sm={4}>
                            <Autocomplete
                              multiple
                              options={users}
                              getOptionLabel={(option) => option.Company_Name}
                              isOptionEqualToValue={(option, value) => option.Company_Name === value.Company_Name}
                              onChange={(event, value) => {
                                const selectedEmails = value.map((employee) => employee.Company_Name);
                                setSelectedEmployees(selectedEmails);
                                // setFieldValue('employees', selectedEmails);
                                setFieldValue('Department_Id', selectedEmails);
                              }}
                              value={users.filter((employee) => selectedEmployees.includes(employee.Company_Name))}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Select Companies"
                                  name="Department_Id"
                                  placeholder="Select Companies"
                                  // error={Boolean(touched.employees && errors.employees)}
                                  // helperText={touched.employees && errors.employees}
                                  error={Boolean(touched.Department_Id && errors.Department_Id)}
                                  helperText={touched.Department_Id && errors.Department_Id}
                                  onBlur={handleBlur}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {isSelectDepartments && (
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              multiple
                              options={corpsubscriptions}
                              getOptionLabel={(option) => option.Subscription_Name}
                              isOptionEqualToValue={(option, value) => option.Subscription_Name === value.Subscription_Name}
                              onChange={(event, value) => {
                                const selectedDepartmentNames = value.map((department) => department.Subscription_Name);
                                setSelectedDepartments(selectedDepartmentNames);
                                setFieldValue('Subscription_Type', selectedDepartmentNames);
                              }}
                              value={corpsubscriptions.filter((department) => selectedDepartments.includes(department.Subscription_Name))}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Select Subscription"
                                  name="Subscription_Type"
                                  placeholder="Select Subscription"
                                  error={Boolean(touched.Subscription_Type && errors.Subscription_Type)}
                                  helperText={touched.Subscription_Type && errors.Subscription_Type}
                                  onBlur={handleBlur}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {selectedOption === 'all' && (
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="All Companies"
                              value={users.map(user => user.Company_Name).join(', ')}
                              InputProps={{
                                readOnly: true,
                              }}
                              variant="outlined"
                              multiline
                              rows={4}
                              // error={Boolean(touched.employees && errors.employees)}
                              // helperText={touched.employees && errors.employees}
                              error={Boolean(touched.Department_Id && errors.Department_Id)}
                              helperText={touched.Department_Id && errors.Department_Id}
                            />
                          </Grid>
                        )}
                      </>
                    }
                  </Grid>
                </CardContent>
                <Divider sx={{ margin: 0 }} />
                <CardActions>
                  <Box sx={{ width: '100%', height: '108px', padding: '36px 0px 36px 0px', gap: '24px', display: 'flex', justifyContent: 'flex-end' }}>
                    {isElevateCoupon ?
                      <Button type='submit' variant="contained" color="primary" size="medium" disabled={isSubmitted}>
                        Share Coupon
                      </Button>
                      :
                      <Button type='submit' variant="contained" color="primary" size="medium" disabled={isSubmitted}>
                        Send Coupon
                      </Button>
                    }
                  </Box>
                </CardActions>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddCorporateCoupon;