import {
    CORP_COUPON_CREATE_FAIL,
    CORP_COUPON_CREATE_REQUEST,
    CORP_COUPON_CREATE_SUCCESS,

    CORP_COUPON_IMAGES_FAIL,
    CORP_COUPON_IMAGES_REQUEST,
    CORP_COUPON_IMAGES_SUCCESS,

    CORP_COUPON_SUBSCRIPTION_FAIL,
    CORP_COUPON_SUBSCRIPTION_REQUEST,
    CORP_COUPON_SUBSCRIPTION_SUCCESS,

    CORP_COUPON_UPDATE_FAIL,
    CORP_COUPON_UPDATE_REQUEST,
    CORP_COUPON_UPDATE_SUCCESS,

    CORP_COMPANY_NAMES_REQUEST,
    CORP_COMPANY_NAMES_SUCCESS,
    CORP_COMPANY_NAMES_FAIL,

    CORP_COMPANY_EMPLOYEES_REQUEST,
    CORP_COMPANY_EMPLOYEES_SUCCESS,
    CORP_COMPANY_EMPLOYEES_FAIL
} from "../actions/corporatecouponAction";


const initialState = {
    corpcouponImages: { couponImage: {}, error: '', isLoading: false },
    corpcouponCreate: { corpcoupon: {}, error: '', isLoading: false },
    corpcouponUpdate: { corpcoupon: {}, error: '', isLoading: false },
    corpsubscriptions: { subscriptions: [], error: '', isLoading: false },
    corpcompanynames: { companynames: [], error: '', isLoading: false },
    corpcompanyemployees: { companyemployees: [], error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {

        case CORP_COUPON_SUBSCRIPTION_REQUEST:
            return { ...state, corpsubscriptions: { subscriptions: {}, error: '', isLoading: true } };
        case CORP_COUPON_SUBSCRIPTION_SUCCESS:
            return { ...state, corpsubscriptions: { subscriptions: action.payload, error: '', isLoading: false } };
        case CORP_COUPON_SUBSCRIPTION_FAIL:
            return { ...state, corpsubscriptions: { subscriptions: {}, error: action.payload, isLoading: false } };

        case CORP_COUPON_IMAGES_REQUEST:
            return { ...state, corpcouponImages: { couponImage: state.corpcouponImages.couponImage, error: '', isLoading: true }, };
        case CORP_COUPON_IMAGES_SUCCESS:
            return { ...state, corpcouponImages: { couponImage: action.payload, error: '', isLoading: false } };
        case CORP_COUPON_IMAGES_FAIL:
            return { ...state, corpcouponImages: { couponImage: [], error: action.payload, isLoading: false } };

        case CORP_COUPON_CREATE_REQUEST:
            return { ...state, corpcouponCreate: { corpcoupon: {}, error: '', isLoading: true } };
        case CORP_COUPON_CREATE_SUCCESS:
            return { ...state, corpcouponCreate: { corpcoupon: state.corpcouponCreate.corpcoupon, error: '', isLoading: false } };
        case CORP_COUPON_CREATE_FAIL:
            return { ...state, corpcouponCreate: { corpcoupon: {}, error: action.payload, isLoading: false } };

        case CORP_COUPON_UPDATE_REQUEST:
            return { ...state, corpcouponUpdate: { corpticket: {}, error: '', isLoading: true } };
        case CORP_COUPON_UPDATE_SUCCESS:
            return { ...state, corpcouponUpdate: { corpticket: state.corpcouponUpdate.corpcoupon, error: '', isLoading: false } };
        case CORP_COUPON_UPDATE_FAIL:
            return { ...state, corpcouponUpdate: { corpticket: {}, error: action.payload, isLoading: false } };

        case CORP_COMPANY_NAMES_REQUEST:
            return { ...state, corpcompanynames: { companynames: {}, error: '', isLoading: true } };
        case CORP_COMPANY_NAMES_SUCCESS:
            return { ...state, corpcompanynames: { companynames: action.payload, error: '', isLoading: false } };
        case CORP_COMPANY_NAMES_FAIL:
            return { ...state, corpcompanynames: { companynames: {}, error: action.payload, isLoading: false } };

        case CORP_COMPANY_EMPLOYEES_REQUEST:
            return { ...state, corpcompanyemployees: { companyemployees: {}, error: '', isLoading: true } };
        case CORP_COMPANY_EMPLOYEES_SUCCESS:
            return { ...state, corpcompanyemployees: { companyemployees: action.payload, error: '', isLoading: false } };
        case CORP_COMPANY_EMPLOYEES_FAIL:
            return { ...state, corpcompanyemployees: { companyemployees: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}