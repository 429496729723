
import { toast } from 'react-toastify';
import { paginationServicesBanners } from './node-actions/nodepagination';
import { boomiAdminApi, nodeServiceApi } from './commonAxios';
 
export const SERVICE_BANNER_CREATE_REQUEST = "SERVICE_BANNER_CREATE_REQUEST";
export const SERVICE_BANNER_CREATE_SUCCESS = "SERVICE_BANNER_CREATE_SUCCESS";
export const SERVICE_BANNER_CREATE_FAIL = "SERVICE_BANNER_CREATE_FAIL";
export const SERVICE_BANNER_UPDATE_REQUEST = "SERVICE_BANNER_UPDATE_REQUEST";
export const SERVICE_BANNER_UPDATE_SUCCESS = "SERVICE_BANNER_UPDATE_SUCCESS";
export const SERVICE_BANNER_UPDATE_FAIL = "SERVICE_BANNER_UPDATE_FAIL ";
 
export const SERVICE_BANNER_GET_REQUEST ="SERVICE_BANNER_GET_REQUEST";
export const SERVICE_BANNER_GET_SUCCESS ="SERVICE_BANNER_GET_SUCCESS";
export const SERVICE_BANNER_GET_FAIL ="SERVICE_BANNER_GET_FAIL";
 
export const SERVICE_BANNER_REQUEST ="SERVICE_BANNER_REQUEST";
export const SERVICE_BANNER_SUCCESS ="SERVICE_BANNER_SUCCESS";
export const SERVICE_BANNER_FAIL ="SERVICE_BANNER_FAIL";
 
export const SERVICE_CATEGORY_REQUEST ="SERVICE_CATEGORY_REQUEST";
export const SERVICE_CATEGORY_SUCCESS ="SERVICE_CATEGORY_SUCCESS";
export const SERVICE_CATEGORY_FAIL ="SERVICE_CATEGORY_FAIL";
 
export const SERVICE_SUBCATEGORY_REQUEST ="SERVICE_SUBCATEGORY_REQUEST"
export const SERVICE_SUBCATEGORY_SUCCESS ="SERVICE_SUBCATEGORY_SUCCESS"
export const SERVICE_SUBCATEGORY_FAIL ="SERVICE_SUBCATEGORY_FAIL"
 
export const SERVICE_VARIENT_REQUEST ="SERVICE_VARIENT_REQUEST"
export const SERVICE_VARIENT_SUCCESS ="SERVICE_VARIENT_SUCCESS"
export const SERVICE_VARIENT_FAIL ="SERVICE_VARIENT_FAIL"


/*=============================================================
                  URL GENERATOR
==============================================================*/

const urlGenerator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
      queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
 
 
/*=============================================================
                  Add ServiceCategory Action
==============================================================*/
export const AddBannertypeAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICE_BANNER_CREATE_REQUEST
  });
  nodeServiceApi.post(`/admin/mps_banners/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_BANNER_CREATE_SUCCESS
        });
        toast('Service Category Created Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicebanners-management');
      } else {
        dispatch({
          type: SERVICE_BANNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast(successResponse.UI_Display_Message+"please change Service Name and Try Again ", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }
    })
    .catch(error => {
      dispatch({
        type:SERVICE_BANNER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};
 
/*=============================================================
                Update ServiceCategory Action
===============================================================*/
export const EditBannertypeAction = (formData, navigate) => async dispatch => {
  dispatch({
    type: SERVICE_BANNER_UPDATE_REQUEST
  });
  nodeServiceApi.put(`/admin/mps_banners/Update/${formData.Banner_Id}`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: SERVICE_BANNER_UPDATE_SUCCESS
        });
        toast('Service Banner Updated Successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        navigate('/app/servicebanners-management');
      } else {  
        dispatch({
          type: SERVICE_BANNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
         
        });
        toast(successResponse.UI_Display_Message+"please change Service Name and Try Again ", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }

      
    })
    .catch(error => {
      dispatch({
        type: SERVICE_BANNER_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};
 
/*=============================================================
              Delete  ServiceCategory Action
 ===============================================================*/
export const deleteBannertypeAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
  try {
    let { data } = await nodeServiceApi.delete(`/admin/mps_banners/Delete/${formData.Banner_Id}`,);
    if (data) {
    
      toast(' Service Banner deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
    dispatch(paginationServicesBanners(filters, pagination, callBackPagination));
  }
catch (err) {
  console.log(err)
  }
};
 
/*=============================================================
           ServiceCategory logo Image Upload Action
===============================================================*/
export const BanneruploadImagepath = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await boomiAdminApi.post(
      `/SA_Upload/Upload?functionality=ServiceCategoryType&fileextension=png&filetype=Images&filename=ServiceCategoryType`,
      formData
    );
    if (data) {
      
      callBackUploadImgae(data);
    }
  } catch (err) {
    console.error('Image upload failed:', err);
  
  }
};
 
export const getservicesbyid = (
  formData = {}, pagination = {},
  responseFunction
) => async (dispatch) => {
  nodeServiceApi.post(urlGenerator('/admin/services/get/', pagination),
      formData,
 
  ).then(response => {
      let data = response.data;
      if (data.Success_Response.Is_Data_Exist === "0") {
          responseFunction(true, []);
           } else {
          try {
              responseFunction(true, data.results, data.Pagination);
          } catch (error) {
              responseFunction(true, data.results);
          }
      }
  }).catch(error => {
      responseFunction(false, error);
  });
};


export const getsubservicesbyid = (
  formData = {}, pagination = {},
  responseFunction
) => async (dispatch) => {
  nodeServiceApi.post(urlGenerator('/admin/sub_service/Get/', pagination),
      formData,
 
  ).then(response => {
      let data = response.data;
      if (data.Success_Response.Is_Data_Exist === "0") {
          responseFunction(true, []);
      } else {
          try {
              responseFunction(true, data.results, data.Pagination);
          } catch (error) {
              responseFunction(true, data.results);
          }
      }
  }).catch(error => {
      responseFunction(false, error);
  });
};
 
 

export const getvarientsbyid = (
  formData = {}, pagination = {},
  responseFunction
) => async (dispatch) => {
  nodeServiceApi.post(urlGenerator('/admin/service_variant/Get/', pagination),
      formData,
 
  ).then(response => {
      let data = response.data;
      if (data.Success_Response.Is_Data_Exist === "0") {
          responseFunction(true, []);
      } else {
          try {
              responseFunction(true, data.results, data.Pagination);
          } catch (error) {
              responseFunction(true, data.results);
          }
      }
  }).catch(error => {
      responseFunction(false, error);
  });
};
 